import {
  Person,
  Search as SearchIcon,
  ShoppingCart,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Drawer,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { GoArrowRight } from "react-icons/go";
import { HiHeart } from "react-icons/hi2";
import { IoLogOutOutline } from "react-icons/io5";
import { MdClose, MdOutlineSpaceDashboard } from "react-icons/md";
import { TfiMenu } from "react-icons/tfi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { postLogout } from "../redux/features/LoginSlice";
import { getProducts } from "../redux/features/ProductSlice";
import MobileNav from "./MobileNav";
import SearchedProductCard from "./product/SearchedProductCard";

const NavbarOpt = ({
  scrollToTop,
  sortedCategories,
  subDropdowns,
  toggleSubDropdown,
  categories,
  dropdowns,
  toggleDropdown,
}) => {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  const products = useSelector((state) => state.mainproduct.searchedProducts);
  const isLoading = useSelector((state) => state.mainproduct.isLoading);
  const wishlistCount = useSelector((state) => state.wishlist.wishlist.length);
  const me = useSelector((state) => state.login.me);

  const carts = useSelector((state) => state.cart.carts || []);

  const totalQuantity = carts.reduce(
    (total, cart) => total + (cart.qty || 0),
    0
  );

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(postLogout());
  };

  // Close the drawer if the screen width is greater than a certain breakpoint

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const breakpoint = 767; // Adjust the breakpoint as needed
    if (windowWidth > breakpoint) {
      setIsOpen(false);
    }
  }, [windowWidth]);

  // TODO: search the products

  useEffect(() => {
    const debouncedDispatch = debounce(() => {
      setShowSearch(true);
      if (search !== "") {
        dispatch(getProducts({ limit: 5, search }));
      }
    }, 1000); // Adjust the delay as needed

    debouncedDispatch();

    return () => {
      debouncedDispatch.cancel();
    };
  }, [dispatch, search]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowSearch(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, setSearch]);

  return (
    <div className="items-center flex">
      <div className="items-center hidden xs:flex relative">
        <TextField
          value={search}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isLoading ? (
                  <div className="small-loader" />
                ) : (
                  <>
                    {search !== "" ? (
                      <MdClose
                        size={20}
                        className="cursor-pointer"
                        onClick={() => setSearch("")}
                      />
                    ) : (
                      <SearchIcon />
                    )}
                  </>
                )}
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          className="border px-2 py-1 rounded w-52"
        />
        {showSearch && search !== "" ? (
          <div
            ref={dropdownRef}
            className="fixed z-40 w-fit top-[4rem] bg-white"
          >
            {products?.length ? (
              <div className="max-h-[50vh] shadow-lg rounded overflow-y-auto flex flex-col gap-3 py-3">
                {products?.map((product, index) => (
                  <SearchedProductCard key={index} product={product} />
                ))}
                <Link
                  onClick={() => setShowSearch(false)}
                  to={`/search?q=${search}`}
                  className="flex justify-center w-full uppercase font-bold items-center bg-black text-white py-1"
                >
                  View all
                  <GoArrowRight size={20} />
                </Link>
              </div>
            ) : (
              <div className="p-gap shadow-lg rounded w-full flex justify-center items-center font-bold text-2xl">
                No results
              </div>
            )}
          </div>
        ) : null}
      </div>

      <Link to={"/wishlist"}>
        <IconButton color="inherit">
          <Badge badgeContent={wishlistCount || 0} color="secondary">
            <HiHeart />
          </Badge>
        </IconButton>
      </Link>

      <Link to={"/cart"}>
        <IconButton color="inherit">
          <Badge badgeContent={totalQuantity || 0} color="secondary">
            <ShoppingCart />
          </Badge>
        </IconButton>
      </Link>

      {me?.id ? (
        <IconButton color="inherit" onClick={handleClick}>
          <Badge color="secondary">
            <Avatar sx={{ width: 30, height: 30 }}>
              {me?.name?.charAt(0)?.toUpperCase()}
            </Avatar>
          </Badge>
        </IconButton>
      ) : (
        <Link to={"/login"}>
          <IconButton color="inherit">
            <Person />
          </IconButton>
        </Link>
      )}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <Link
            to="/dashboard"
            className="flex items-center gap-2 mb-3 cursor-pointer"
          >
            <MdOutlineSpaceDashboard className="text-xl" />
            <h5>Dashboard</h5>
          </Link>
        </MenuItem>

        <MenuItem onClick={handleLogout}>
          <div className="flex items-center gap-2 cursor-pointer">
            <IoLogOutOutline className="text-xl" />
            <h5>Logout</h5>
          </div>
        </MenuItem>
      </Menu>

      <div className="block md:hidden">
        <IconButton
          color="inherit"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <TfiMenu />
        </IconButton>
      </div>

      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            height: "100%",
            boxSizing: "border-box",
            overflow: "auto",
            width: "80%",
          },
        }}
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <MobileNav
          onClose={() => setIsOpen(false)}
          sortedCategories={sortedCategories}
          toggleDropdown={toggleDropdown}
          dropdowns={dropdowns}
          categories={categories}
          toggleSubDropdown={toggleSubDropdown}
          subDropdowns={subDropdowns}
          scrollToTop={scrollToTop}
        />
      </Drawer>
    </div>
  );
};

export default NavbarOpt;
