import React from "react";

const NoData = () => {
  return (
    <div className="bg-white p-8 w-fit mx-auto">
      {/* <h1 className="text-2xl font-bold mb-2">No Data Available</h1> */}
      <p className="text-gray-600">
        There is currently no data to display. Please check back later.
      </p>
    </div>
  );
};

export default NoData;
