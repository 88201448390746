import React from "react";

const Loading = () => {
  return (
    <div className="loading-container absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
      <img src={"assets/logo.png"} alt="Loading..." className="blinking-logo" />
    </div>
  );
};

export default Loading;
