/* eslint-disable react/prop-types */
import { Typography } from "@mui/material";
import { GoArrowRight } from "react-icons/go";
import { IoIosHeart, IoMdHeartEmpty } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteWishlist,
  postWishlist,
} from "../../redux/features/WishlistSlice";

const SingleProduct = ({ product }) => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.login.me);

  const wishLists = useSelector((state) => state.wishlist.wishlist);
  const isLoading = useSelector((state) => state.wishlist.isLoading);

  const alreadyWished = wishLists.some(
    (item) => item.product_id === product?.id
  );

  const toggleHeart = () => {
    toast.dismiss();
    if (alreadyWished) {
      dispatch(
        deleteWishlist({
          id: wishLists?.find((data) => data?.product?.id === product?.id)?.id,
        })
      );
    } else {
      dispatch(postWishlist({ user_id: me?.id, product_id: product.id }));
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div>
        <div className="mx-2">
          <div className="relative group">
            <Link to={`/product/${product?.slug}`} onClick={scrollToTop}>
              <img
                src={product?.photo}
                className="w-full aspect-[4/5] object-cover transition-opacity duration-500 ease-in-out opacity-100 group-hover:opacity-0"
                alt="citygarms products"
              />
            </Link>
            <Link to={`/product/${product?.slug}`} onClick={scrollToTop}>
              <img
                src={product?.gallery[0]}
                className="w-full absolute aspect-[4/5] object-cover transition-opacity duration-500 ease-in-out opacity-0 group-hover:opacity-100 top-0 left-0 right-0"
                alt="citygarms products"
              />
            </Link>
            {alreadyWished ? (
              <button disabled={isLoading} onClick={toggleHeart}>
                <IoIosHeart className="absolute cursor-pointer text-2xl top-3 right-3 backdrop-blur-sm text-red-600" />
              </button>
            ) : (
              <button disabled={isLoading} onClick={toggleHeart}>
                <IoMdHeartEmpty className="absolute cursor-pointer text-2xl top-3 right-3 backdrop-blur-sm" />
              </button>
            )}

            <button className="absolute bg-white text-black w-fit tracking-wider font-bold -translate-y-[50%] px-2 group-hover:-translate-y-[100%] duration-150 left-2">
              Rs.{" "}
              {product?.variations?.reduce(
                (lowest, item) => (item.price < lowest.price ? item : lowest),
                product?.variations?.[0]?.selling_price
              )}
            </button>
          </div>
          <div className="flex justify-between items-center gap-4">
            <div className="text-sm">
              <Link to={`/product/${product?.slug}`} onClick={scrollToTop}>
                <h2
                  className="line-clamp-2"
                  style={{
                    height: "3em",
                    lineHeight: "1.5em",
                  }}
                >
                  {product?.name}
                </h2>
                <h2 className="line-clamp-1 opacity-50">
                  {product?.sub_sub_category?.name}
                </h2>

                <Typography
                  variant="button"
                  className="flex items-center gap-2 group pt-1"
                >
                  Shop Now
                  <GoArrowRight
                    className="group-hover:-rotate-45 duration-200"
                    size={20}
                  />
                </Typography>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleProduct;
