// store.js
import { configureStore } from "@reduxjs/toolkit";
// import RegisterSlice from "./features/RegisterSlice";
import bannerSlice from "./features/BannerSlice";
import BrandSlice from "./features/BrandSlice";
import cartSlice from "./features/cartSlice";
import CategorySlice from "./features/CategorySlice";
import LoginSlice from "./features/LoginSlice";
import logoutSlice from "./features/logoutSlice";
import orderSlice from "./features/orderSlice";
import otpSlice from "./features/otpSlice";
import ProductSlice from "./features/ProductSlice";
import RegisterSlice from "./features/RegisterSlice";
import settingSlice from "./features/settingSlice";
import WishlistSlice from "./features/WishlistSlice";

const store = configureStore({
  reducer: {
    login: LoginSlice,
    mainproduct: ProductSlice,
    cart: cartSlice,
    order: orderSlice,
    logout: logoutSlice,
    maincategory: CategorySlice,
    wishlist: WishlistSlice,
    brand: BrandSlice,
    register: RegisterSlice,
    otp: otpSlice,
    banner: bannerSlice,
    setting: settingSlice,
  },
});

export default store;
