// src/features/product/productSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../apiConfig";

const initialState = {
  isLoading: false,
  products: [],
  error: null,
  singleProduct: null,
  navbarProduct: null,
  totalData: null,
  newProducts: [],
  featuredProducts: [],
  trendingProducts: [],
  searchedProducts: [],
  weeklyProducts: [],
  offerProducts: [],
};

export const getNavbarProduct = createAsyncThunk(
  "getNavbarProduct/product",
  async ({ sub_category_id, sub_sub_category_id, category_id }, thunkApi) => {
    try {
      const res = await api.get("products", {
        params: {
          sub_sub_category_id,
          sub_category_id,
          category_id,
          stock: 1,
          status: 1,
        },
      });
      return res.data.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getProducts = createAsyncThunk(
  "getProducts/product",
  async (
    {
      limit,
      category_id,
      featured,
      latest,
      trending,
      sub_category_id,
      sub_sub_category_id,
      search,
      weekly,
    },
    thunkApi
  ) => {
    try {
      const res = await api.get(`products`, {
        params: {
          category_id,
          sub_category_id,
          sub_sub_category_id,
          limit,
          featured,
          new: latest,
          trending,
          search,
          stock: 1,
          status: 1,
          weekly_deal: weekly,
        },
      });
      return {
        data: res.data.data,
        featured,
        latest,
        trending,
        search,
        weekly,
      };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getOfferProducts = createAsyncThunk(
  "getOfferProducts/product",
  async ({ limit, featured, latest, trending, search }, thunkApi) => {
    try {
      const res = await api.get(`offer`, {
        params: {
          limit,
          search,
          stock: 1,
          status: 1,
        },
      });
      return {
        data: res.data.data,
        featured,
        latest,
        trending,
        search,
      };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getSingleProduct = createAsyncThunk(
  "getSingleProduct/product",
  async ({ slug }, thunkApi) => {
    try {
      const res = await api.get(`products/${slug}`);
      return res.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getNavbarProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNavbarProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.navbarProduct = action.payload;
      })
      .addCase(getNavbarProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    // TODO: single product
    builder
      .addCase(getSingleProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleProduct = action.payload;
      })
      .addCase(getSingleProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    // todos: for fetching product
    builder
      .addCase(getProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.isLoading = false;

        if (action.payload.featured) {
          state.featuredProducts = action.payload.data.data;
        } else if (action.payload.latest) {
          state.newProducts = action.payload.data.data;
        } else if (action.payload.trending) {
          state.trendingProducts = action.payload.data.data;
        } else if (action.payload.search) {
          state.searchedProducts = action.payload.data.data;
        } else if (action.payload.weekly) {
          state.weeklyProducts = action.payload.data.data;
        } else {
          state.products = action.payload.data.data;
        }

        state.totalData = action.payload.total;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    // todos: for fetching offer products
    builder
      .addCase(getOfferProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOfferProducts.fulfilled, (state, action) => {
        state.isLoading = false;

        state.offerProducts = action.payload.data.data;
      })
      .addCase(getOfferProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    // todos: for category wise porduct
    // builder
    //   .addCase(getCategoryProduct.pending, (state) => {
    //     state.isLoading = true;
    //   })
    //   .addCase(getCategoryProduct.fulfilled, (state, action) => {
    //     state.isLoading = false;
    //     state.products = action.payload;
    //   })
    //   .addCase(getCategoryProduct.rejected, (state, action) => {
    //     state.isLoading = false;
    //     state.error = action.payload;
    //   });
  },
});

export default productSlice.reducer;
