import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../apiConfig";
import { toast } from "react-toastify";

const initialState = {
  isLoading: false,
  logout: [],
  error: null,
};

export const postLogout = createAsyncThunk(
  "postLogout/logout",
  async (data, thunkApi) => {
    try {
      const res = await api.post("logout", data);
      localStorage.removeItem('token');
      return res.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const logoutSlice = createSlice({
  name: "logout",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postLogout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postLogout.fulfilled, (state, action) => {
        state.isLoading = false;
        state.logout = action.payload;
        toast.success("Logout Successfully!")
      })
      .addCase(postLogout.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
     //    state.isLoggedIn = false;
      });
  },
});

export default logoutSlice.reducer;