// Redux/features/otpSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../apiConfig"; // Assuming you have an API configuration

const initialState = {
  isLoading: false,
  error: null,
};

export const postOTP = createAsyncThunk(
  "postOTP/otp",
  async ({ otp, user_id, navigate }, thunkApi) => {
    try {
      const response = await api.post("/verify-otp", { otp, user_id });
      localStorage.setItem("token", response.data.token);
      return { data: response.data.data, navigate };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const resendOTP = createAsyncThunk(
  "resendOTP/otp",
  async ({ phone, user_id }, thunkApi) => {
    try {
      const response = await api.post("/resend-otp", { phone, user_id });
      return response.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const otpSlice = createSlice({
  name: "otp",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postOTP.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(postOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        toast.success("Registered successfully!");
        localStorage.removeItem("phone");
        localStorage.removeItem("id");
        action.payload.navigate && action.payload.navigate("/");
        window.location.reload();
      })
      .addCase(postOTP.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        toast.error(
          JSON.stringify(action.payload.response.data.message || action.payload)
        );
      });

    builder
      .addCase(resendOTP.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(resendOTP.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
        toast.success("An OTP has been sent to your phone");
      })
      .addCase(resendOTP.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        toast.error(JSON.stringify(action.payload.message || action.payload));
      });
  },
});

export default otpSlice.reducer;
