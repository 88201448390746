import { useContext, useEffect } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CartCard from "../components/CartCard";
import Loading from "../components/Loading";
import CartContext from "../context/CartContext";
import { fetchCartItems } from "../redux/features/cartSlice";

const CartPage = () => {
  const dispatch = useDispatch();
  const carts = useSelector((state) => state.cart.carts || []);
  const isLoading = useSelector((state) => state.cart.isLoading);
  const user_id = useSelector((state) => state.login.me?.id);
  const user = useSelector((state) => state.login.me);
  const setting = useSelector((state) => state.setting.settings);

  const { setCartSummary } = useContext(CartContext);

  useEffect(() => {
    if (user_id) {
      dispatch(fetchCartItems({ user_id }));
    }
  }, [dispatch, user_id]);

  const totalPrice = carts?.reduce((total, item) => {
    const price = item.price ? Number(item.price) : 0;
    const quantity = item.qty ? Number(item.qty) : 1;
    return total + price * quantity;
  }, 0);

  const deliveryCharge = setting.delivery_charges || 0;

  const grandTotal = totalPrice + deliveryCharge;

  useEffect(() => {
    setCartSummary({ totalPrice, deliveryCharge, grandTotal });
  }, [totalPrice, deliveryCharge, grandTotal, setCartSummary]);

  if (!user) {
    return (
      <div className="container py-16 mx-auto md:w-[30%] px-4 md:px-0">
        <p className="text-2xl mb-5 font-medium text-center">
          You are not logged in. Please log in to view your cart.
        </p>
        <Link className="flex justify-center" to="/register">
          <button className="flex justify-center items-center gap-1 hover:bg-white hover:text-black bg-black text-white duration-300 border border-black p-3 px-5 uppercase rounded-md">
            Register Here
            <IoArrowBackOutline />
          </button>
        </Link>
      </div>
    );
  }

  // TODO: check whether all items are in stock
  const allInStock = !carts?.find((cart) => cart?.variation?.stock === 0)?.id;

  console.log(allInStock, "all in stock");

  return (
    <div className="container pb-20">
      <h1 className="text-3xl text-white font-semibold mb-6">Your Cart</h1>
      {user && (
        <div className="grid grid-cols-1 lg:grid-cols-12 px-4 gap-8">
          <div className="col-span-full md:col-span-8">
            {isLoading ? (
              <div className="flex justify-center h-[calc(100vh-4rem)] items-center">
                <Loading />
              </div>
            ) : (
              <>
                {carts?.length === 0 ? (
                  <div className="">
                    <p className="text-2xl mb-5 font-medium">
                      Your cart is empty.
                    </p>
                    <Link to="/">
                      <button className="flex items-center gap-1 hover:bg-white hover:text-black bg-black text-white duration-300 border border-black p-3 px-5 uppercase">
                        <IoArrowBackOutline /> Go back to shopping
                      </button>
                    </Link>
                  </div>
                ) : (
                  <ul className="gap-6 space-y-5">
                    <div className="flex flex-col gap-5">
                      {carts?.map((item, index) => (
                        <CartCard key={index} item={item} />
                      ))}
                    </div>
                  </ul>
                )}
              </>
            )}
          </div>

          {carts?.length > 0 && (
            <div className="col-span-full sticky top-32 md:col-span-4 border h-[17rem] md:w-[60%] lg:w-full bg-white opacity-95 border-gray-200 shadow rounded-3xl p-8">
              <h2 className="font-semibold text-center text-xl">
                ORDER SUMMARY
              </h2>
              <div>
                <div className="flex items-center justify-between">
                  <p className="py-3">Price</p>
                  <p>Rs. {totalPrice}</p>
                </div>
                <div className="flex items-center justify-between">
                  <p>Delivery Charge</p>
                  <p>Rs. {deliveryCharge}</p>
                </div>
                <hr className="border mt-2 border-gray-300" />
                <div className="flex items-center justify-between">
                  <p className="pt-3 font-semibold">Total</p>
                  <p className="font-semibold">Rs. {grandTotal}</p>
                </div>
              </div>
              <Link to="/checkout">
                <button
                  disabled={!allInStock}
                  className={`cart-checkout-btn mt-4 ${
                    !allInStock ? "bg-gray-500" : "bg-black"
                  } text-white py-3 px-5 w-full rounded-md`}
                >
                  Proceed to Checkout
                </button>
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CartPage;
