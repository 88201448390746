import React, { useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const NextArrow = ({ onClick }) => (
  <div
    className="absolute top-1/2 w-12 h-12 bg-black flex justify-center items-center opacity-50 text-white transform -translate-y-1/2 right-0 z-10 cursor-pointer"
    onClick={onClick}
  >
    <IoIosArrowForward size={30} />
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div
    className="absolute top-1/2 transform -translate-y-1/2 left-0 z-10 cursor-pointer w-12 h-12 bg-black flex justify-center items-center opacity-50 text-white"
    onClick={onClick}
  >
    <IoIosArrowBack size={30} />
  </div>
);

const BgBanner = ({ bg }) => {
  const sliderRef = useRef(null);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    // beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    // responsive: [
    //   {
    //     breakpoint: 3000,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },

    //   {
    //     breakpoint: 630,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //     },
    //   },

    //   {
    //     breakpoint: 425,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };

  return (
    <div>
      {bg?.length > 1 ? (
        <>
          <Slider ref={sliderRef} {...settings}>
            {bg?.map((b, index) => (
              <div>
                <div key={index}>
                  <div
                    // to={`/search?q=${b?.category?.slug || b?.slug}`}
                    className="w-full"
                  >
                    <img
                      alt=""
                      src={b?.image}
                      className="w-full object-center"
                    />{" "}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </>
      ) : (
        <>
          {bg?.map((b, index) => (
            <div key={index}>
              <div
                // to={`/search?q=${b?.category?.slug || b?.slug}`}
                className="w-full"
              >
                <img alt="" src={b?.image} className="w-full object-center" />
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default BgBanner;
