import { Avatar, Box } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { postRegister } from "../redux/features/RegisterSlice";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  const { isLoading } = useSelector((state) => state.register);
  const me = useSelector((state) => state.login.me);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
    referral_code: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    phone: Yup.string().required("Required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    const dataToSubmit = { ...values };
    delete dataToSubmit.referral_code;

    dispatch(postRegister(dataToSubmit))
      .unwrap()
      .then(() => {
        resetForm();
        navigate("/otp-page");
        // toast.success("Successfully Register!"); // Clear the form after successful registration
      })
      .catch((error) => {
        // Error handling is done through toast in slice, no need to do it here
      });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPassword = () => {
    setConfirmPassword(!confirmPassword);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (me?.phone_verified_at) {
      navigate("/");
    }
  }, [me, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-register_bg bg-no-repeat bg-cover">
      <div className="p-6 bg-opacity-15 backdrop-blur-[1] bg-white shadow-lg rounded-xl my-gap md:w-[50rem] w-[90%] ">
        <div className="flex flex-col gap-2 justify-center items-center">
          {/* <Avatar sx={{ width: 100, height: 100 }} /> */}
          <h2 className="text-center text-3xl font-medium text-white">
            Create a new account
          </h2>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {() => (
            <Form>
              <div className="rounded-md shadow-sm">
                <Box
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  }}
                >
                  <div className="w-full">
                    <h2 className="mt-4 text-white">Name</h2>
                    <Field
                      id="name"
                      name="name"
                      type="text"
                      autoComplete="name"
                      placeholder="name"
                      className="py-3 px-3 rounded-md border border-gray-300 w-full hover:border-black duration-200"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  <div>
                    <h2 className="mt-4 text-white">Email</h2>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      placeholder="Email"
                      className="py-3 px-3 rounded-md border border-gray-300 w-full hover:border-black duration-200"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  <div>
                    <h2 className="mt-4 text-white">Phone Number</h2>
                    <Field
                      id="phone"
                      name="phone"
                      type="tel"
                      autoComplete="tel"
                      placeholder="Phone Number"
                      className="py-3 px-3 rounded-md border border-gray-300 w-full hover:border-black duration-200"
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  <div>
                    <h2 className="mt-4 text-white">Password</h2>
                    <div className="relative">
                      <Field
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        autoComplete="new-password"
                        placeholder="Password"
                        className="py-3 px-3 rounded-md border border-gray-300 w-full hover:border-black duration-200"
                      />
                      <span
                        className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
                        onClick={toggleShowPassword}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </span>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  <div>
                    <h2 className="mt-4 text-white">Confirm Password</h2>
                    <div className="relative">
                      <Field
                        id="confirm_password"
                        name="confirm_password"
                        type={confirmPassword ? "text" : "password"}
                        autoComplete="new-password"
                        placeholder="Confirm Password"
                        className="py-3 px-3 rounded-md border border-gray-300 w-full hover:border-black duration-200"
                      />
                      <span
                        className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
                        onClick={toggleConfirmPassword}
                      >
                        {confirmPassword ? "Hide" : "Show"}
                      </span>
                    </div>
                    <ErrorMessage
                      name="confirm_password"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  <div>
                    <h2 className="mt-4 text-white">Referral Code</h2>
                    <Field
                      id="referral_code"
                      name="referral_code"
                      type="text"
                      autoComplete="referral-code"
                      placeholder="Referral Code"
                      className="py-3 px-3 rounded-md border border-gray-300 w-full hover:border-black duration-200"
                    />
                    <ErrorMessage
                      name="referral_code"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </Box>
              </div>
              <div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="py-3 px-6 rounded-full mt-5 w-full bg-black hover:bg-white hover:text-black transition-all duration-500 border hover:border-black text-white"
                >
                  {isLoading ? "Registering..." : "Register"}
                </button>
              </div>

              <div className="text-sm flex mt-4">
                <p className="font-medium text-white text-center w-full">
                  Already have an account?
                  <Link
                    onClick={scrollToTop}
                    to="/login"
                    className="text-white underline ml-2"
                  >
                    Login
                  </Link>
                </p>
              </div>
            </Form>
          )}
        </Formik>
        <div className="text-sm text-center mt-4">
          <Link
            onClick={scrollToTop}
            to="/"
            className="flex items-center gap-2 font-medium text-white"
          >
            <BsArrowLeft /> Back to Home Page
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Register;
