import React from "react";

const Privacy = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-10 px-5 md:px-20 lg:px-40">
      <div className="bg-white p-10 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold mb-6 text-center">Return Policy</h1>

        <h1 className="text-2xl font-semibold text-gray-800 mb-4">
          For Exchange and Return
        </h1>
        <p className="text-gray-600 mb-4">
          All online orders placed on CITYGARMS.com that were paid with
          Credit/Debit Card/Esewa/Cash must be sent back to the warehouse within
          7 days of the delivery-date to be processed for an exchange or return.
        </p>
        <p className="text-gray-600 mb-4">
          Once your package arrives at the warehouse, we'll check the quality
          and if we received all items. Once we have processed your return, we
          immediately initiate your exchange or refund process. This normally
          takes 2-3 working days.
        </p>

        <p className="text-gray-600 mb-4">
          Your refund will be issued to the same payment method that you used
          when ordering. Depending on your payment method, refunds can take up
          to 3-5 working days.
        </p>

        <p className="text-gray-600 mb-4">
          In case of Cash purchase, you can either choose to top-up your balance
          in your City Garms account for your next purchase or you can receive
          your refund via Esewa. This process normally takes up to 24 hours.
        </p>
        <p className="text-gray-600 mb-4">
          We'll send you an email as soon as we've processed your exchange or
          return is authorised.
        </p>

        <h1 className="text-2xl font-semibold text-gray-800 mb-4">
          Store Locator
        </h1>

        <p className="text-gray-600 mb-4">
          City Garms Pvt Ltd currently operates on an online platform. For the
          time being, there are no physical shop.
        </p>

        <p className="text-gray-600 mb-4">
          We have website and social sites Instagram and Facebook where
          everything is automated and carefully designed to make the shopping
          experience quick, easy, and convenient. Both website and social sites
          can also be used to chat with our sales representative if you have any
          questions about the product, quality, price, delivery, or anything
          else. For your convenience, you can also confirm and place an order
          with the agent via the social platform.
        </p>

        <p className="text-gray-600 mb-4">
          For any other queries about the product, returns, exchanges, or other
          support, please email us at admin@citygarms.com or WhatsApp us
          on +977-9803411049. Our friendly customer service team will serve you
          for orders, exchanges, or any other questions you may have.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
