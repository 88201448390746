import { Avatar } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { postLogin } from "../redux/features/LoginSlice";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector((state) => state.login.isLoading);
  const me = useSelector((state) => state.login.me);

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (me?.phone_verified_at) {
      navigate("/");
    }
  }, [me, navigate]);

  return (
    <div className="min-h-[calc(100vh-4rem)] flex items-center justify-center bg-gray-100 bg-register_bg bg-no-repeat bg-cover">
      <div className="p-6 bg-opacity-15 backdrop-blur-[1] bg-white shadow-lg rounded-xl my-gap md:w-[25rem] w-[90%]">
        <div className="flex flex-col gap-2 justify-center items-center">
          <Avatar sx={{ width: 100, height: 100 }} />
          <h2 className="text-xl font-medium flex justify-between items-center">
            <span className="text-white">Log in to your account</span>
          </h2>
        </div>

        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("Invalid email address")
              .required("Required"),
            password: Yup.string().required("Required"),
          })}
          onSubmit={(values) => {
            dispatch(postLogin({ data: values, navigate }));
          }}
        >
          <Form className="space-y-7">
            <div className="rounded-md ">
              <div className="mt-5">
                <h2 className="text-base text-white">Email</h2>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="Email"
                  className="py-3 px-3 rounded-md border border-gray-300 w-full hover:border-black duration-200"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="">
                <h2 className="mt-4 text-base text-white">Password</h2>
                <div className="relative">
                  <Field
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="new-password"
                    placeholder="Password"
                    className="py-3 px-3 rounded-md border border-gray-300 w-full hover:border-black duration-200"
                  />
                  <span
                    className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? "Hide" : "Show"}
                  </span>
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center hover:bg-white hover:border-black hover:text-black duration-500 rounded-full py-3 px-4 border border-transparent text-sm font-medium text-white bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                disabled={isLoading}
              >
                {isLoading ? "Logging in..." : "Log in"}
              </button>
            </div>
            <div className="md:flex items-center text-center justify-between">
              {/* <div className="text-sm pb-2 md:pb-0">
                <a href="#" className="font-medium text-white">
                  Forgot your password?
                </a>
              </div> */}
              <div className="text-sm flex">
                <p className="font-medium text-white">
                  Don&apos;t have an account?
                  <Link
                    onClick={scrollToTop}
                    to="/register"
                    className="text-white underline ml-2"
                  >
                    Register
                  </Link>
                </p>
              </div>
            </div>
          </Form>
        </Formik>
        <div className="text-sm text-center mt-4">
          <Link
            onClick={scrollToTop}
            to="/"
            className="flex items-center gap-2 font-medium text-white"
          >
            <BsArrowLeft /> Back to Home Page
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
