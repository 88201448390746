// Redux/features/settingSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import api from "../../apiConfig"; // Assuming you have an API configuration

const initialState = {
  isLoading: false,
  settings: 0,
};

export const fetchSettings = createAsyncThunk(
  "fetchSettings/settings",
  async (_, thunkApi) => {
    try {
      const response = await api.get("/settings");

      return response.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.settings = action.payload;
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default settingSlice.reducer;
