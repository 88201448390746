// src/features/banner/bannerSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../apiConfig";

const initialState = {
  isLoading: false,
  banners: [],
  offers: [],
  error: null,
};

export const getBanners = createAsyncThunk(
  "getBanners/banner",
  async (_, thunkApi) => {
    try {
      const res = await api.get(`banners`, {
        params: {
          type: "banner",
        },
      });
      return {
        data: res.data.data,
      };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getOffers = createAsyncThunk(
  "getOffers/banner",
  async (_, thunkApi) => {
    try {
      const res = await api.get(`banners`, {
        params: {
          type: "ad",
        },
      });
      return {
        data: res.data.data,
      };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  extraReducers: (builder) => {
    // todos: for fetching banner
    builder
      .addCase(getBanners.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBanners.fulfilled, (state, action) => {
        state.isLoading = false;
        state.banners = action.payload.data;
      })
      .addCase(getBanners.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    // todos: for fetching offer
    builder
      .addCase(getOffers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOffers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.offers = action.payload.data;
      })
      .addCase(getOffers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default bannerSlice.reducer;
