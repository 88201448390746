import React from "react";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { CiMobile3 } from "react-icons/ci";
import { FaMoneyCheck } from "react-icons/fa";
import { LiaShippingFastSolid } from "react-icons/lia";
import { MdMarkEmailRead } from "react-icons/md";
import { RiMessage2Line } from "react-icons/ri";
import { RxLoop } from "react-icons/rx";

import { TbRosetteDiscount } from "react-icons/tb";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Footer = ({ setMenuSlug }) => {
  const scrollToTop = () => {
    setMenuSlug("/");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const categories = useSelector((state) => state.maincategory.categories);

  const filteredCategory = (categories?.data || categories)?.filter(
    (category) => !category?.parent_id
  );

  return (
    <div className="border-t border-gray-300 mt-gap">
      <div className="container grid lg:grid-cols-5 md:grid-cols-3 gap-y-6 text-center py-6 gap-gap">
        <div className="flex flex-col items-center">
          <LiaShippingFastSolid className="text-3xl" />
          <h2 className="font-semibold pt-2 text-sm">FAST DELIVERY</h2>
          <p className="text-xs">
            Free Delivery on Rs. 2000+ spent or with Click and Collect.
          </p>
        </div>

        <div className="flex flex-col items-center">
          <FaMoneyCheck className="text-3xl" />
          <h2 className="font-semibold pt-2 uppercase text-sm">SAFE PAYMENT</h2>
          <p className="text-xs">
            Secure and trusted payment options. COD Available.
          </p>
        </div>

        <div className="flex flex-col items-center">
          <RxLoop className="text-3xl" />
          <h2 className="font-semibold pt-2 uppercase text-sm">
            Quick and Easy Return
          </h2>
          <p className="text-xs">
            Items can be returned or exchanged up to 7 days after purchase.
          </p>
        </div>

        <div className="flex flex-col items-center">
          <TbRosetteDiscount className="text-3xl" />
          <h2 className="font-semibold pt-2 text-sm">ONLINE DISCOUNT</h2>
          <p className="text-xs">
            Enjoy exclusive discounts when you shop online.
          </p>
        </div>

        <div className="flex flex-col items-center">
          <RiMessage2Line className="text-3xl" />
          <h2 className="font-semibold pt-2 text-sm">HELP CENTER</h2>
          <p className="text-xs">
            We're here to assist you with any questions or concerns.
          </p>
        </div>
      </div>

      <hr />
      <div className="container py-12">
        <div className="md:grid-cols-2 lg:grid-cols-4 grid lg:px-0 gap-5">
          <div className="">
            <div className="lg:w-[40%] w-[24%]">
              <Link onClick={scrollToTop} to="/">
                <img src="/assets/logo.png" className="" alt="logo" />
              </Link>
            </div>
            <div className="w-[100%]">
              <h2 className="mt-4 pb-7">
                The content on this site is protected and owned by City Garms
              </h2>
            </div>
            <div className="flex gap-7 text-3xl">
              <a
                href="https://www.facebook.com/share/fbmi5yxTzL34q3B2/?mibextid=LQQJ4d"
                target="__blank"
              >
                <BsFacebook />
              </a>
              <a href="https://www.instagram.com/ctygarms/" target="__blank">
                <BsInstagram />
              </a>
              {/* <FaTiktok /> */}
            </div>
          </div>
          <div>
            <h2 className="text-xl pb-4  lg:py-0 font-semibold">
              Useful Links
            </h2>
            <ul className="leading-8">
              <Link to="/tracking" onClick={scrollToTop}>
                <li className="hover:underline ">Order Tracking</li>
              </Link>
              <Link to="/return-policy" onClick={scrollToTop}>
                <li className="hover:underline ">Return Policy</li>
              </Link>
              <Link to="/terms" onClick={scrollToTop}>
                <li className="hover:underline ">Terms & Conditions</li>
              </Link>

              <Link to="/faqs" onClick={scrollToTop}>
                <li className="hover:underline ">FAQs</li>
              </Link>

              <Link to="/dashboard" onClick={scrollToTop}>
                <li className="hover:underline ">User Dashboard</li>
              </Link>
            </ul>
          </div>
          <div>
            <h2 className="text-xl pb-4  lg:py-0 font-semibold">Category</h2>
            <ul className="leading-8">
              {filteredCategory?.map((cat, index) => (
                <Link key={index} to={cat?.slug} onClick={scrollToTop}>
                  <li className="hover:underline capitalize">
                    {cat?.name?.toLowerCase()}
                  </li>
                </Link>
              ))}
            </ul>
          </div>
          <div>
            <h2 className="text-xl pb-4 lg:py-0 font-semibold">Contacts</h2>
            <ul className="leading-8">
              <li className="flex items-center gap-2">
                <MdMarkEmailRead />{" "}
                <a
                  href="mailto:info@citygarms.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  info@citygarms.com
                </a>
              </li>
              <li className="flex items-center gap-2">
                <CiMobile3 />{" "}
                <a
                  href="https://wa.me/9803411049"
                  target="_blank"
                  rel="noreferrer"
                >
                  +977 9803411049
                </a>
              </li>
              {/* <li className="flex items-center gap-2">
                  <CiLocationOn />
                  Baneshwor, Kathmandu
                </li> */}
            </ul>
          </div>
        </div>
      </div>
      <hr className="border-[1px]" />
      <div className="container md:flex justify-between text-center text-sm md:text-base py-3">
        <h2 className="pb-2 md:pb-0">
          &copy; Copyright 2024 Citygarms All Rights Reserved
        </h2>
        <h2>
          Design and developed by{" "}
          <a
            href="https://apptechnologies.co/"
            target="blank"
            className="font-semibold"
          >
            App Technologies
          </a>
        </h2>
      </div>
    </div>
  );
};

export default Footer;
