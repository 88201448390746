import { Avatar, Box, Divider, Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";

export const MyProfile = () => {
  const me = useSelector((state) => state.login.me);

  return (
    <Paper className="p-4">
      <Typography variant="h6" className="mb-4">
        My Profile
      </Typography>
      <Divider
        sx={{
          my: 1,
        }}
      />
      <Box className="flex items-center mb-4">
        <Avatar
          src="/path/to/avatar.png"
          alt="Avatar"
          className="w-16 h-16 mr-4"
        />
        <Box>
          <Typography variant="body1">{me?.name}</Typography>
          <Typography variant="body2">{me?.email}</Typography>
        </Box>
      </Box>
      <Box className="mb-4">
        <Typography variant="body2">Phone: {me?.phone}</Typography>
      </Box>
      {/* <Button variant="contained">Edit Profile</Button> */}
    </Paper>
  );
};
