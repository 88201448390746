import { Search as SearchIcon } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { GoArrowRight } from "react-icons/go";
import { IoCloseOutline } from "react-icons/io5";
import { MdClose, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getProducts } from "../redux/features/ProductSlice";
import SearchedProductCard from "./product/SearchedProductCard";

const MobileNav = ({
  scrollToTop,
  sortedCategories,
  toggleDropdown,
  dropdowns,
  categories,
  toggleSubDropdown,
  subDropdowns,
  onClose,
}) => {
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");

  const products = useSelector((state) => state.mainproduct.searchedProducts);
  const isLoading = useSelector((state) => state.mainproduct.isLoading);

  // TODO: search the products

  useEffect(() => {
    const debouncedDispatch = debounce(() => {
      if (search !== "") {
        dispatch(getProducts({ limit: 5, search }));
      }
    }, 2000); // Adjust the delay as needed

    debouncedDispatch();

    return () => {
      debouncedDispatch.cancel();
    };
  }, [dispatch, search]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSearch("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, setSearch]);

  return (
    <div className="p-5">
      <div className="flex justify-between items-center">
        <Link onClick={scrollToTop} to="/">
          <img
            src="/assets/logo.png"
            className="w-14"
            alt="logo"
            onClick={onClose}
          />
        </Link>
        <IoCloseOutline className="text-3xl cursor-pointer" onClick={onClose} />
      </div>
      <div className="items-center flex xs:hidden mt-3">
        <TextField
          value={search}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isLoading ? (
                  <div className="small-loader" />
                ) : (
                  <>
                    {search !== "" ? (
                      <MdClose
                        size={20}
                        className="cursor-pointer"
                        onClick={() => setSearch("")}
                      />
                    ) : (
                      <SearchIcon />
                    )}
                  </>
                )}
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          className="border px-2 py-1 rounded"
        />
        {search !== "" ? (
          <div
            ref={dropdownRef}
            className="fixed z-40 w-fit top-[8rem] bg-white"
          >
            {products?.length ? (
              <div className="h-[50vh] shadow-lg rounded overflow-y-auto flex flex-col gap-3 py-3">
                {products?.map((product, index) => (
                  <SearchedProductCard key={index} product={product} />
                ))}
                <Link
                  to={`/search?q=${search}`}
                  className="flex justify-center w-full uppercase font-bold items-center bg-black text-white py-1"
                >
                  View all
                  <GoArrowRight size={20} />
                </Link>
              </div>
            ) : (
              <div className="p-gap shadow-lg rounded w-full flex justify-center items-center font-bold text-2xl">
                No results
              </div>
            )}
          </div>
        ) : null}
      </div>

      <ul className="mt-5 space-y-4">
        {sortedCategories?.map((category) => (
          <li key={category.id}>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleDropdown(category.slug)}
            >
              <Link
                to={`/${category.slug}`}
                className="text-lg font-medium"
                onClick={onClose}
              >
                {category.name}
              </Link>
              <MdOutlineKeyboardArrowRight
                className={`transform transition-transform duration-300 ${
                  dropdowns[category.slug] ? "rotate-90" : ""
                }`}
              />
            </div>
            {dropdowns[category.slug] && (
              <ul className="mt-2 pl-4 space-y-2">
                {categories
                  ?.filter((cat) => cat.parent_id === category.id)
                  .map((subCategory) => (
                    <li key={subCategory.id}>
                      <div
                        className="flex justify-between items-center cursor-pointer"
                        onClick={() => toggleSubDropdown(subCategory.slug)}
                      >
                        <Link
                          to={`/${category.slug}/${subCategory.slug}`}
                          className="text-base"
                          onClick={onClose}
                        >
                          {subCategory.name}
                        </Link>
                        <MdOutlineKeyboardArrowRight
                          className={`transform transition-transform duration-300 ${
                            subDropdowns[subCategory.slug] ? "rotate-90" : ""
                          }`}
                        />
                      </div>
                      {subDropdowns[subCategory.slug] && (
                        <ul className="mt-2 pl-4 space-y-2">
                          {categories
                            ?.filter((cat) => cat.parent_id === subCategory.id)
                            .map((subSubCategory) => (
                              <li key={subSubCategory.id}>
                                <Link
                                  to={`/${category.slug}/${subCategory.slug}/${subSubCategory.slug}`}
                                  className="text-sm"
                                  onClick={onClose}
                                >
                                  {subSubCategory.name}
                                </Link>
                              </li>
                            ))}
                        </ul>
                      )}
                    </li>
                  ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MobileNav;
