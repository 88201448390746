import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../apiConfig";
import { resendOTP } from "./otpSlice";

const initialState = {
  isLoading: false,
  logins: null,
  error: null,
  isLoggedIn: false,
  me: null,
  forgotPassword: null,
};

export const postLogin = createAsyncThunk(
  "postLogin/login",
  async ({ data, navigate }, thunkApi) => {
    try {
      const res = await api.post("login", data);

      if (!res.data.data.phone_verified_at) {
        localStorage.setItem("phone", res.data.data.phone);
        localStorage.setItem("id", res.data.data.id);
        navigate("/otp-page");
        toast.info("Your phone is not verified yet.");
        thunkApi.dispatch(
          resendOTP({
            phone: res.data.data.phone,
            user_id: res.data.data.id,
          })
        );
      }

      return {
        data: res.data.data,
        token: res.data.token,
        ...(res.data.data.phone_verified_at && { navigate }),
      };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchMe = createAsyncThunk(
  "fetchMe/login",
  async (_, thunkApi) => {
    try {
      const res = await api.get("auth-user");

      return res.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const postLogout = createAsyncThunk(
  "postLogout/logout",
  async (data, thunkApi) => {
    try {
      const res = await api.post("logout", data);
      localStorage.removeItem("token");
      return res.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const postForgotPassword = createAsyncThunk(
  "postForgotPassword/ForgotPassword",
  async (data, thunkApi) => {
    try {
      const res = await api.post("forgot-password", data);
      return res.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk(
  "changePassword/login",
  async ({ data }, thunkApi) => {
    try {
      const res = await api.post("change-password", data);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logoutSuccess(state) {
      state.isLoggedIn = false;
      localStorage.removeItem("token");
      localStorage.removeItem("id");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postLogin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.me = action.payload.data;
      action.payload.navigate &&
        localStorage.setItem("token", action.payload.token);
      action.payload.navigate && toast.success("Logged in Successfully!");
      // action.payload.navigate && action.payload.navigate("/");
    });
    builder.addCase(postLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isLoggedIn = false;
      toast.error(JSON.stringify(action.payload.response.data.message));
    });

    builder.addCase(postLogout.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(postLogout.fulfilled, (state, action) => {
      state.isLoading = false;
      window.location.reload();
    });
    builder.addCase(postLogout.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isLoggedIn = false;
      toast.error("Error while loggin out.");
    });

    // TODO: change password
    builder.addCase(changePassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.isLoading = false;
      toast.success("Password changed successfully.");
      window.location.reload();
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.response.data.message);
    });

    // For forgotPassword
    builder.addCase(postForgotPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postForgotPassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.forgotPassword = action.payload;
      state.isLoggedIn = true;
    });
    builder.addCase(postForgotPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isLoggedIn = false;
    });

    builder.addCase(fetchMe.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMe.fulfilled, (state, action) => {
      state.isLoading = false;
      state.me = action.payload;
      state.isLoggedIn = true;
    });
    builder.addCase(fetchMe.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isLoggedIn = false;
      state.me = null;
    });
  },
});

export const { logoutSuccess } = loginSlice.actions;
export default loginSlice.reducer;
