import { Button, Divider, Paper, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { changePassword } from "../../redux/features/LoginSlice";

export const ChangePassword = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.login.isLoading);

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Current Password is required"),
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("New Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: async (values) => {
      // Function to handle the form submission

      dispatch(
        changePassword({
          data: {
            password: values.password,
            confirmPassword: values.confirmPassword,
          },
        })
      );
    },
  });

  return (
    <Paper className="p-4">
      <Typography variant="h6" className="mb-2">
        Change Password
      </Typography>
      <Divider
        sx={{
          my: 1,
        }}
      />
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-3">
          <TextField
            label="Current Password"
            variant="outlined"
            fullWidth
            className="mb-4"
            id="password"
            name="password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <TextField
            label="New Password"
            variant="outlined"
            fullWidth
            className="mb-4"
            id="newPassword"
            name="newPassword"
            type="password"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            helperText={formik.touched.newPassword && formik.errors.newPassword}
          />
          <TextField
            label="Confirm Password"
            variant="outlined"
            fullWidth
            className="mb-4"
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
        </div>
        <Button
          disabled={isLoading}
          variant="contained"
          type="submit"
          sx={{
            mt: 3,
            background: "black",
          }}
        >
          {isLoading ? "Loading ..." : "Change Password"}
        </Button>
      </form>
    </Paper>
  );
};
