import axios from "axios";

const apiConfig = {
  baseURL: process.env.REACT_APP_BASE_URL,
};

const api = axios.create(apiConfig);

// Add an interceptor to set the Authorization header before each request
api.interceptors.request.use(
  (config) => {
    // Get the token from local storage
    const token = localStorage.getItem("token");
    // If a token exists, set the Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
