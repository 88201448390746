import React from "react";

const Terms = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-10 px-5 md:px-20 lg:px-40">
      <div className="bg-white p-10 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold mb-6 text-center">
          Terms and Conditions
        </h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">TRADEMARKS</h2>
          <p className="text-gray-700 leading-6">
            Except otherwise specified, all designs, logos, images, graphics,
            website contents are trademarks of City Garms or its licensees or
            sponsors and are protected by trademark laws. The trademarks may not
            be used in any way that is likely to cause confusion or to degrade
            or discredit City Garms.
          </p>
          <br />
          <p className="text-gray-700 leading-6">
            City Garms and its logo are, without restriction, among City Garms
            and its Affiliates' registered trademarks. Infringement of any City
            Garms trademark is strictly prohibited.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">COPYRIGHTS</h2>
          <p className="text-gray-700 leading-6">
            All information, including product designs and any related
            collateral materials is owned by CityGarms and/or its licensees and
            is protected by copyright laws and/or design patents. You agree not
            to use, display, reproduce, retransmit, modify, distribute,
            disseminate, sell, publish, create derivative works of, broadcast,
            circulate, display, or otherwise exploit any such content, in whole
            or in part, for any purpose other than your personal, non-commercial
            use as set forth herein. You are not permitted to utilise any
            product designs without the express written permission of CityGarms.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">OUR PRODUCTS</h2>
          <p className="text-gray-700 leading-6">
            CityGarms makes every effort to provide accurate product
            descriptions. CityGarms does not, however, represent that product
            and pricing descriptions, or other material on this site, are
            accurate, comprehensive, reliable, or error-free.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">RETURNS</h2>
          <p className="text-gray-700 leading-6">
            To guarantee a great purchasing experience for all of our customers,
            we may restrict or prohibit future transactions from such consumers
            if we find an inappropriate return trend. We also retain the right,
            in our sole business discretion, to cancel accounts and limit
            purchases.
          </p>
        </section>

        <p className="text-center text-gray-600 mt-8">
          If you have any questions about these Terms, please contact us at
          9803411049.
        </p>
      </div>
    </div>
  );
};

export default Terms;
