import { useState } from "react";
import { BiTargetLock } from "react-icons/bi"; // Importing react-icons
import { useSelector } from "react-redux";

const OrderTrackingPage = () => {
  const orderStatus = useSelector((state) => state.order.orderStatus);
  const isLoading = useSelector((state) => state.order.isLoading);
  const [trackingNumber, setTrackingNumber] = useState("");

  const handleTrackingNumberChange = (e) => {
    setTrackingNumber(e.target.value);
  };

  return (
    <div className="bg-gray-100 min-h-[70vh] flex items-center justify-center">
      <div className="bg-gray-200 p-8 w-[90%] md:w-[50%] lg:w-[40%] rounded-md shadow-lg">
        <h1 className="text-2xl md:text-3xl text-center font-semibold mb-4 text-gray-800">
          Enter reference number
        </h1>

        <div className="mb-4">
          <input
            type="text"
            value={trackingNumber}
            onChange={handleTrackingNumberChange}
            placeholder="ex. AGAI6J9KH12V"
            className="border rounded-md border-gray-400 px-4 py-2 w-full focus:outline-none"
          />
        </div>

        <div className="flex flex-col items-center mb-4">
          {trackingNumber === "" ? (
            <button
              target="__blank"
              disabled
              className="flex items-center justify-center text-white px-5 py-2 rounded-md bg-black focus:outline-none"
            >
              <BiTargetLock className="mr-2 text-xl" />
              {isLoading ? "Tracking ..." : "Track your delivery"}
            </button>
          ) : (
            <a
              target="__blank"
              href={`https://www.nepalcanmove.com/track/?id=${trackingNumber}`}
              className="flex items-center justify-center text-white px-5 py-2 rounded-md bg-black focus:outline-none"
            >
              <BiTargetLock className="mr-2 text-xl" />
              {isLoading ? "Tracking ..." : "Track your delivery"}
            </a>
          )}

          <div className="text-sm flex flex-col items-center mt-5">
            <p className="font-medium">Missed your delivery?</p>{" "}
            <p>
              Contact Line: <span className="font-semibold">9803411049</span>
            </p>
          </div>
        </div>

        <div className="mt-8 text-center">
          <p className="text-xs text-gray-600 mt-2">
            <span className="font-bold uppercase">Note:</span> City Garms Pvt.
            Ltd. dispatches all deliveries via Nepal Can Move. Please enter the
            reference number you have received. If you have not received a
            reference number, kindly contact us, and we will provide you with
            one.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderTrackingPage;
