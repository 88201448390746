/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BgBanner from "../components/BgBanner";
import { getProducts } from "../redux/features/ProductSlice";
import Featured from "../sections/landing/Featured";
import NewArrivals from "../sections/landing/NewArrivals";
import Trending from "../sections/landing/Trending";
import MainCategories from "../sections/mainCategoryProducts/MainCategories";

const MainCategoryProduct = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.maincategory.categories);

  const { slug } = useParams();

  const banners = useSelector((state) => state.banner.banners);

  const categoryId = categories?.find(
    (cat) => cat?.slug === slug || cat?.slug === slug?.slice(0, -1)
  )?.id;

  const [bannerData, setBannerData] = useState([]);

  useEffect(() => {
    if (categories?.find((cat) => cat?.slug === slug)?.id) {
      dispatch(
        getProducts({
          category_id: categories?.find((cat) => cat?.slug === slug)?.id,
        })
      );
    }
  }, [dispatch, categories, slug]);

  useEffect(() => {
    setBannerData(banners?.filter((d) => d?.category?.slug === slug));
  }, [slug, banners]);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // if (!banners.some((d) => d.slug === slug)) {
  //   return <Navigate to={"/404"} />;
  // }

  return (
    <div>
      <Helmet>
        <title>
          {slug.charAt(0).toUpperCase() + slug.slice(1, slug.length)} |
          Citygarms
        </title>
      </Helmet>
      <BgBanner bg={bannerData} />
      <div className="flex flex-col gap-gap">
        <MainCategories slug={slug} />
        <NewArrivals category_id={categoryId} />
        <Trending category_id={categoryId} />
        <Featured category_id={categoryId} />
      </div>
    </div>
  );
};

export default MainCategoryProduct;
