import React from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import SingleProduct from "../components/product/SingleProduct";

const WishList = () => {
  const wishlist = useSelector((state) => state.wishlist.wishlist);
  const user = useSelector((state) => state.login.me);

  if (!user) {
    return (
      <div className="container py-16 mx-auto md:w-[30%] px-4 md:px-0">
        <p className="text-2xl mb-5 font-medium text-center">
          You are not logged in. Please log in to view your cart.
        </p>
        <Link className="flex justify-center" to="/register">
          <button className="flex justify-center items-center gap-1 hover:bg-white hover:text-black bg-black text-white duration-300 border border-black p-3 px-5 uppercase rounded-md">
            Register Here
            <IoArrowBackOutline />
          </button>
        </Link>
      </div>
    );
  }

  return (
    <div className="relative h-full">
      <div className="absolute inset-0"></div>
      <div className="relative container mx-auto p-4 py-12">
        <h2 className="text-3xl font-semibold mb-6">Wishlist</h2>
        {wishlist?.length === 0 ? (
          <p>No items in your wishlist.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-3 xs:grid-cols-2 lg:grid-cols-4 gap-5 w-full">
            {wishlist?.map((item, index) => (
              <SingleProduct key={index} product={item?.product} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default WishList;
