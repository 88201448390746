import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useDispatch } from "react-redux";
import { deleteCartItem, updateCart } from "../redux/features/cartSlice";

const CartCard = ({ item }) => {
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedVariation, setSelectedVariation] = useState(null);

  const handleOpenDialog = (id) => {
    setSelectedItemId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedItemId(null);
  };

  const handleDelete = (id) => {
    dispatch(deleteCartItem(id));
    setOpenDialog(false);
  };

  useEffect(() => {
    if (item) {
      if (item?.product?.variations?.length > 0) {
        setSelectedSize(item?.variation?.size);
        setSelectedColor(item?.variation?.color);
      }
    }
  }, [item]);

  const handleSizeClick = (size) => {
    setSelectedSize((prevSize) => (prevSize === size ? null : size));
  };

  const handleColorClick = (color) => {
    setSelectedColor((prevColor) => (prevColor === color ? null : color));
  };

  useEffect(() => {
    const variation = item?.product?.variations?.find(
      (variation) =>
        variation?.size?.id === selectedSize?.id &&
        variation?.color?.id === selectedColor?.id
    );

    console.log(variation, "varrrriatiooonn");
    setSelectedVariation(variation);
  }, [item?.product?.variations, selectedColor, selectedSize]);

  console.log(selectedVariation, "selected variationnn");

  useEffect(() => {
    if (
      selectedVariation?.id &&
      selectedVariation?.id !== item?.variation?.id
    ) {
      dispatch(
        updateCart({
          id: item?.id,
          data: {
            variation_id: selectedVariation?.id,
          },
        })
      );
    }
  }, [selectedVariation?.id, item?.id, item?.variation?.id, dispatch]);

  return (
    <div className="relative shadow bg-white rounded-lg overflow-hidden flex sm:flex-row flex-col justify-between items-center space-y-2 p-4 gap-5">
      <div className="flex-1">
        <img
          src={item?.product?.photo}
          alt={item?.product?.name}
          className="aspect-[4/5] max-w-full object-contain mr-4 rounded-md"
        />
      </div>
      <div className="flex-[2]">
        <div className="flex-col">
          <p className="font-semibold text-lg">{item?.product?.name}</p>
          {/* <p className="mt-2 font-bold">Rs. {item?.price}</p> */}
        </div>
        <div className="lg:flex items-center gap-12">
          <div className="flex flex-col items-start gap-3">
            {/* <label className="block text-sm font-medium text-gray-700">
              Size:
            </label> */}

            {selectedVariation ? (
              <>
                {selectedVariation?.stock ? (
                  <div className="flex items-center gap-3">
                    {selectedVariation?.price !==
                    selectedVariation?.selling_price ? (
                      <del className="text-xl font-bold text-[#3d3d3d]">
                        Rs. {selectedVariation?.price}
                      </del>
                    ) : null}
                    <p className="text-2xl font-semibold">
                      Rs. {selectedVariation?.selling_price}
                    </p>
                  </div>
                ) : (
                  <h1 className="uppercase text-red-500 font-extrabold">
                    Out of Stock{" "}
                    <span className="lowercase text-sm font-normal text-wrap">
                      (remove from cart or select other variant)
                    </span>
                  </h1>
                )}
              </>
            ) : (
              <p className="text-2xl font-semibold text-red-500">
                No matched variation.
              </p>
            )}
            {item?.product?.variations?.[0]?.size?.name === "Default" ? null : (
              <div className="flex gap-3 items-center">
                <label className="text-gray-600 font-medium">Size:</label>

                <div className="flex gap-3 flex-wrap">
                  {[
                    ...new Set(
                      item?.product?.variations?.map(
                        (variation) => variation.size?.name
                      )
                    ),
                  ].map((size, index) => {
                    const variation = item?.product.variations.find(
                      (variation) => variation.size?.name === size
                    );
                    return (
                      <button
                        disabled={selectedSize?.id === variation?.size?.id}
                        key={index}
                        className={`border border-gray-300 rounded-lg px-4 py-2 cursor-pointer duration-200 hover:border-black flex items-center gap-2`}
                        onClick={() => handleSizeClick(variation.size)}
                      >
                        <div
                          className={`w-4 h-4 rounded-full border border-black ${
                            selectedSize?.id === variation?.size?.id
                              ? "p-[2px]"
                              : ""
                          }`}
                        >
                          <div
                            className={`w-full h-full rounded-full ${
                              selectedSize?.id === variation?.size?.id
                                ? "bg-black text-white"
                                : ""
                            }`}
                          />
                        </div>
                        <span>{variation?.size?.name}</span>
                      </button>
                    );
                  })}
                </div>
              </div>
            )}

            {item?.product?.variations?.[0]?.color?.name ===
            "Default" ? null : (
              <div className="flex gap-3 items-center">
                <label className="text-gray-600 font-medium">Color:</label>

                <div className="flex gap-3 flex-wrap">
                  {[
                    ...new Set(
                      item?.product?.variations?.map(
                        (variation) => variation.color?.name
                      )
                    ),
                  ].map((colorName, index) => {
                    const variation = item?.product.variations.find(
                      (variation) => variation.color?.name === colorName
                    );
                    return (
                      <button
                        disabled={selectedColor?.id === variation?.color?.id}
                        key={index}
                        className={`border border-gray-300 rounded-lg px-4 py-2 cursor-pointer duration-200 hover:border-black flex items-center gap-2`}
                        onClick={() => handleColorClick(variation.color)}
                      >
                        <div
                          className={`w-4 h-4 rounded-full border border-black ${
                            selectedColor?.id === variation?.color?.id
                              ? "p-[2px]"
                              : ""
                          }`}
                        >
                          <div
                            className={`w-full h-full rounded-full ${
                              selectedColor?.id === variation?.color?.id
                                ? "bg-black text-white"
                                : ""
                            }`}
                          />
                        </div>

                        <span>{variation?.color?.name}</span>
                      </button>
                    );
                  })}
                </div>
              </div>
            )}
            {/* <FormControl fullWidth>
              <TextField
                defaultValue={item?.variation?.id}
                select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => {
                  dispatch(
                    updateCart({
                      id: item?.id,
                      data: {
                        variation_id: e.target.value,
                      },
                    })
                  );
                }}
              >
                {item?.product?.variations?.map((variation) => (
                  <MenuItem key={variation.size.id} value={variation?.id}>
                    <span>{variation?.size?.name}</span>
                  </MenuItem>
                ))}
              </TextField>
            </FormControl> */}
            <div className="flex items-center space-x-2 py-3">
              <label className="block text-sm font-medium text-gray-700">
                Qty:
              </label>
              <button
                disabled={item?.qty === 1 || !selectedVariation?.stock}
                className="px-3 bg-gray-100 py-1 border rounded"
                onClick={() =>
                  dispatch(
                    updateCart({
                      id: item?.id,
                      data: {
                        qty: item?.qty - 1,
                      },
                    })
                  )
                }
              >
                -
              </button>
              <span>{item?.qty}</span>
              <button
                disabled={!selectedVariation?.stock}
                className="px-3 bg-gray-100 py-1 border rounded"
                onClick={() =>
                  dispatch(
                    updateCart({
                      id: item?.id,
                      data: {
                        qty: item?.qty + 1,
                      },
                    })
                  )
                }
              >
                +
              </button>
            </div>
          </div>
          {/* <div className="flex items-center space-x-2 py-3">
            <label className="block text-sm font-medium text-gray-700">
              Qty:
            </label>
            <button
              disabled={item?.qty === 1}
              className="px-3 bg-gray-100 py-1 border rounded"
              onClick={() =>
                dispatch(
                  updateCart({
                    id: item?.id,
                    data: {
                      qty: item?.qty - 1,
                    },
                  })
                )
              }
            >
              -
            </button>
            <span>{item?.qty}</span>
            <button
              className="px-3 bg-gray-100 py-1 border rounded"
              onClick={() =>
                dispatch(
                  updateCart({
                    id: item?.id,
                    data: {
                      qty: item?.qty + 1,
                    },
                  })
                )
              }
            >
              +
            </button>
          </div> */}
        </div>
      </div>

      <button
        className="text-black px-4 py-2 text-start absolute top-0 right-0"
        onClick={() => handleOpenDialog(item?.id)}
      >
        <IoMdClose className="text-2xl " />
      </button>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Cart Item?"}</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this item from your cart?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDelete(selectedItemId)}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CartCard;
