import React from "react";
import { Link } from "react-router-dom";
import Recommendation from "../sections/detail/Recommendation";

const NotFound = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center container pt-gap">
      <h1 className="text-4xl font-bold mb-4">PAGE NOT FOUND</h1>

      <p className="mb-4">
        Or return to the{" "}
        <Link to="/" className="text-blue-500 underline">
          Homepage
        </Link>{" "}
        and start again.
      </p>
      <div className="w-full">
        <Recommendation />
      </div>
    </div>
  );
};

export default NotFound;
