import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { getOffers } from "../../redux/features/BannerSlice";
import { getBrand } from "../../redux/features/BrandSlice";
import WeeklyDeals from "./WeeklyDeals";

const Offers = () => {
  const dispatch = useDispatch();
  const brand = useSelector((state) => state.brand.brands);

  const offersData = useSelector((state) => state.banner.offers)[0];

  useEffect(() => {
    dispatch(getBrand());
    dispatch(getOffers());
  }, [dispatch]);

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          // dots: true
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container flex flex-col gap-gap">
      <div
        className={`gap-gap ${
          offersData?.image ? "grid lg:grid-cols-2 grid-cols-1" : ""
        } h-fit`}
      >
        {offersData?.image ? (
          <Link to={"/offer-products"}>
            <img src={offersData?.image} className="object-contain" alt="" />
          </Link>
        ) : null}
        <div
          className={`flex-1 ${
            offersData?.image
              ? "grid grid-cols-1 gap-5"
              : "grid grid-cols-1 lg:grid-cols-2 gap-5"
          }`}
        >
          <div className="border-gray-300 border rounded-xl p-3 shadow-lg h-fit">
            <h2 className="font-semibold px-3 md:text-2xl">Top Brands</h2>
            {brand?.length > 2 ? (
              <Slider {...settings} className="rounded-xl">
                {brand?.map((item, index) => (
                  <div
                    key={index}
                    className="px-4 py-4 flex-none focus:outline-none"
                  >
                    <img
                      src={item?.photo}
                      className="rounded-lg object-cover w-full aspect-square"
                      alt="brand"
                    />
                    <h2 className="px-2 mt-1">{item?.name}</h2>
                  </div>
                ))}
              </Slider>
            ) : (
              <div className="rounded-xl flex flex-wrap">
                {brand?.map((item, index) => (
                  <div
                    key={index}
                    className="px-4 py-4 flex-none focus:outline-none"
                  >
                    <img
                      src={item?.photo}
                      className="rounded-lg object-cover w-28 aspect-square"
                      alt="brand"
                    />
                    <h2 className="px-2 mt-1">{item?.name}</h2>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="hidden lg:block">
            <WeeklyDeals />
          </div>
        </div>
      </div>
      <div className="block lg:hidden">
        <WeeklyDeals />
      </div>
    </div>
  );
};

export default Offers;
