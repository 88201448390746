import React, { useEffect } from "react";
import { TiArrowBack } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Loading from "../components/Loading";
import SingleProduct from "../components/product/SingleProduct";
import { getOfferProducts, getProducts } from "../redux/features/ProductSlice";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const AllProducts = ({ offer = false }) => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.mainproduct.products);
  const offerProducts = useSelector((state) => state.mainproduct.offerProducts);
  const loading = useSelector((state) => state.mainproduct.isLoading);
  const query = useQuery();
  const searchQuery = query.get("q") || "";

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (offer) {
      dispatch(getOfferProducts({ limit: 50 }));
    } else {
      dispatch(getProducts({ limit: 50 }));
    }
  }, [dispatch, offer, searchQuery]);

  return (
    <>
      {offer ? (
        <div className="container md:pb-14 bg-white overflow-hidden">
          {loading ? (
            <div className="flex justify-center h-[calc(100vh-4rem)] items-center">
              <Loading />
            </div>
          ) : offerProducts?.length === 0 ? (
            <div className="text-center py-12 md:pt-24 px-4 md:px-0">
              <img
                src="/images/nodataimg.png"
                className="sm:w-[60%] lg:w-[40%] mx-auto pb-8"
                alt=""
              />
              <h2 className="uppercase font-semibold text-3xl">
                opps no result for offers.
              </h2>
              <p>
                Don&apos;t give up! Check the spelling or try something else.
              </p>
            </div>
          ) : (
            <div>
              <h2 className="py-8 pt-10 italic text-2xl font-semibold uppercase flex flex-col gap-3">
                <Link
                  to={"/"}
                  className="flex items-center gap-2 hover:bg-black hover:text-white w-fit px-2 py-1 transition-all duration-150"
                >
                  <TiArrowBack /> <span className="text-sm">Go Back</span>
                </Link>
                All Offers
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-y-6">
                {offerProducts?.map((product, index) => (
                  <SingleProduct key={index} product={product} />
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="container md:pb-14 bg-white overflow-hidden">
          {loading ? (
            <div className="flex justify-center h-[calc(100vh-4rem)] items-center">
              <Loading />
            </div>
          ) : products?.length === 0 ? (
            <div className="text-center py-12 md:pt-24 px-4 md:px-0">
              <img
                src="/images/nodataimg.png"
                className="sm:w-[60%] lg:w-[40%] mx-auto pb-8"
                alt=""
              />
              <h2 className="uppercase font-semibold text-3xl">
                opps no result for search product "{searchQuery}"
              </h2>
              <p>
                Don&apos;t give up! Check the spelling or try something else.
              </p>
            </div>
          ) : (
            <div>
              <h2 className="py-8 pt-10 italic text-2xl font-semibold uppercase flex flex-col gap-3">
                <Link
                  to={"/"}
                  className="flex items-center gap-2 hover:bg-black hover:text-white w-fit px-2 py-1 transition-all duration-150"
                >
                  <TiArrowBack /> <span className="text-sm">Go Back</span>
                </Link>
                All Products
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-y-6">
                {products?.map((product, index) => (
                  <SingleProduct key={index} product={product} />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AllProducts;
