import React from "react";

const Faq = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-10 px-5 md:px-20 lg:px-40">
      <div className="bg-white p-10 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold mb-6 text-center">
          Frequently Asked Questions
        </h1>
        <h1 className="text-2xl font-semibold text-gray-800 mb-4">
          How can I purchase products form City Garms?
        </h1>
        <p className="text-gray-600 mb-4">
          City Garms is currently only available online.
        </p>
        <p className="text-gray-600 mb-4">
          You can search for the products you need on our website,
          www.citygarms.com. The website provides automated and quick service.
          As a result, it is the quickest and fastest method for processing the
          order.
        </p>
        <p className="text-gray-600 mb-4">
          You can also order through our social media platforms, Instagram and
          Facebook. All the products listed on the website will be available on
          both of our social media platforms. You can chat with one of our
          friendly sales representatives for placing your order.
        </p>

        <h1 className="text-2xl font-semibold text-gray-800 mb-4">
          How do I edit or cancel an order?
        </h1>
        <p className="text-gray-600 mb-4">
          If you have already placed the order and paid online, you can chat
          with our customer service team, and they will process your request.
        </p>
        <p className="text-gray-600 mb-4">
          The amount you paid will be refunded to the same payment method that
          you used when ordering.
        </p>
        <p className="text-gray-600 mb-4">
          However, if you placed the order and chose cash on delivery, please
          notify our customer service team as soon as possible so that they do
          not dispatch a delivery for the order.
        </p>
        <p className="text-gray-600 mb-4">
          You can cancel or edit the order by sending us an email at
          info@citygarms.com or messaging us on WhatsApp at +9779803411049. If
          you placed your order through one of our social media sites, you could
          still use the same platform.
        </p>

        <h1 className="text-2xl font-semibold text-gray-800 mb-4">
          How do I book a delivery or collection slot?
        </h1>
        <p className="text-gray-600 mb-4">
          After you complete the shopping and chose the required payment method.
          You will be receiving an automated email confirming your order.
        </p>
        <p className="text-gray-600 mb-4">
          Within next 24 hours, our team will reach via Email/WhatsApp/social
          sites to you to confirm your delivery date or collection slot.
        </p>

        <h1 className="text-2xl font-semibold text-gray-800 mb-4">
          Will I be charged for a delivery?
        </h1>
        <p className="text-gray-600 mb-4">
          For orders under Rs.2,000.00, a minimum of Rs.100 will be charged
          inside the Kathmandu Valley.
        </p>
        <p className="text-gray-600 mb-4">
          Orders placed outside of Kathmandu will be charged between Rs.150 –
          Rs. 250.
        </p>

        <h1 className="text-2xl font-semibold text-gray-800 mb-4">
          How can I get a free delivery?
        </h1>
        <p className="text-gray-600 mb-4">
          As of now, we offer the following payment methods:
        </p>
        <p className="text-gray-600 mb-4">
          Orders placed outside of Kathmandu will be charged between Rs.150 –
          Rs. 250.
          <ol className="list-decimal list-inside space-y-2 mt-5">
            <li>FonePay</li>
            <li>Cash on Delivery</li>
          </ol>
        </p>

        <h1 className="text-2xl font-semibold text-gray-800 mb-4">
          How do I track my delivery order?
        </h1>
        <p className="text-gray-600 mb-4">
          After you complete your payment, you will be receiving an automated
          email conforming your order. You will also receive your order number
          in the same email.
        </p>
        <p className="text-gray-600 mb-4">
          You can then return to citygarms.com and navigate to my orders. You
          then will be able to check the status of your order.
        </p>

        <p className="text-gray-600 mb-4">
          For more information about your delivery, please contact us at
          admin@citygarms.com or send us your order query at +9779803411049
          WhatsApp.
        </p>
      </div>
    </div>
  );
};

export default Faq;
