import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../apiConfig";

const initialState = {
  isLoading: false,
  brands: [],
  error: null,
};

export const getBrand = createAsyncThunk(
  "brands/getBrand",
  async (_, thunkApi) => {
    try {
      const res = await api.get("brands");
      return res.data.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const brandSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBrand.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getBrand.fulfilled, (state, action) => {
        state.isLoading = false;
        state.brands = action.payload;
        state.error = null;
      })
      .addCase(getBrand.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default brandSlice.reducer;
