import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../apiConfig"; // Assuming you have an API configuration

const initialState = {
  isLoading: false,
  error: null,
  register: null,
};

export const postRegister = createAsyncThunk(
  "register/postRegister",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api.post("/register", { ...formData }); // Replace with your actual API endpoint
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data); // Ensure the error response is returned
    }
  }
);

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postRegister.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(postRegister.fulfilled, (state, action) => {
        state.isLoading = false;
        state.register = action.payload;
        localStorage.setItem("phone", action.payload.phone);
        localStorage.setItem("id", action.payload.id);
        state.error = null;
      })
      .addCase(postRegister.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        toast.error(JSON.stringify(action.payload.message || action.payload));
      });
  },
});

export default registerSlice.reducer;
