import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../apiConfig";

const initialState = {
  isLoading: false,
  carts: [],
  error: null,
  items: [],
};

// Define fetchCartItems async thunk
export const fetchCartItems = createAsyncThunk(
  "cart/fetchCartItems",
  async ({ user_id }, thunkAPI) => {
    try {
      const response = await api.get("cart", {
        params: {
          user_id,
        },
      });
      return response.data.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const postCart = createAsyncThunk(
  "postCart/cart",
  async (cartItem, thunkApi) => {
    try {
      const res = await api.post("cart", cartItem);
      return res.data.data; // Assuming res.data.data is the newly added cart item
    } catch (error) {
      if (error.response.status === 401) {
        return thunkApi.rejectWithValue("Please login for this action");
      }
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const updateCart = createAsyncThunk(
  "updateCart/cart",
  async ({ id, data }, thunkApi) => {
    try {
      const res = await api.patch(`cart/${id}`, data);
      return res.data.data; // Assuming res.data.data is the newly added cart item
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const deleteCartItem = createAsyncThunk(
  "deleteCartItem/cart",
  async (id, thunkApi) => {
    try {
      await api.delete(`cart/${id}`);
      return id; // Return the ID of the deleted item
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // Add your additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartItems.pending, (state) => {
        state.isLoading = true;
        state.error = null; // Reset error state on pending
      })
      .addCase(fetchCartItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.carts = action.payload; // Update state with fetched cart items
        state.error = null; // Reset error state on successful fulfillment
      })
      .addCase(fetchCartItems.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload; // Update error state on rejection
      })

      .addCase(postCart.pending, (state) => {
        state.isLoading = true;
        state.error = null; // Reset error state on pending
      })
      .addCase(postCart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.carts.push(action.payload); // Append the newly added item to the cart
        // toast.success(JSON.stringify(action.payload.message || action.payload));
        state.error = null; // Reset error state on successful fulfillment
      })
      .addCase(postCart.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        toast.error(JSON.stringify(action.payload.message || action.payload));
      })

      .addCase(updateCart.pending, (state) => {
        state.isLoading = true;
        state.error = null; // Reset error state on pending
      })
      .addCase(updateCart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.carts = state.carts.map((cart) => {
          if (cart?.id === action.payload.id) {
            return action.payload;
          } else return cart;
        });
        state.error = null;
      })
      .addCase(updateCart.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        toast.error(JSON.stringify(action.payload.message || action.payload));
      })

      .addCase(deleteCartItem.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteCartItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.carts = state.carts.filter((cart) => cart.id !== action.payload); // Filter out the deleted item
        state.error = null;
      })
      .addCase(deleteCartItem.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        toast.error(JSON.stringify(action.payload.message || action.payload));
      });
  },
});

export const { actions, reducer } = cartSlice;
export default cartSlice.reducer;
