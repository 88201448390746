// src/components/MainProduct.jsx
import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Heading from "../../components/Heading";
import Loading from "../../components/Loading";
import NoData from "../../components/NoData";
import SingleProduct from "../../components/product/SingleProduct";
import { getProducts } from "../../redux/features/ProductSlice";

const NextArrow = ({ onClick }) => (
  <div
    className="absolute top-1/2 w-12 h-12 bg-black flex justify-center items-center opacity-50 text-white transform -translate-y-1/2 -right-8 z-10 cursor-pointer"
    onClick={onClick}
  >
    <IoIosArrowForward size={30} />
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div
    className="absolute top-1/2 transform -translate-y-1/2 -left-8 z-10 cursor-pointer w-12 h-12 bg-black flex justify-center items-center opacity-50 text-white"
    onClick={onClick}
  >
    <IoIosArrowBack size={30} />
  </div>
);

const CustomScrollbar = ({ totalSlides, currentSlide, goToSlide }) => {
  const percentage = (currentSlide / (totalSlides - 1)) * 100;

  return (
    <div className="relative mt-10">
      <div className="h-1 bg-gray-200 rounded-full">
        <div
          className="h-1 bg-black rounded-full"
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      <input
        type="range"
        min="0"
        max={totalSlides - 1}
        value={currentSlide}
        onChange={(e) => goToSlide(Number(e.target.value))}
        className="absolute top-0 left-0 w-full h-2 opacity-0 cursor-pointer"
      />
    </div>
  );
};

const NewArrivals = ({ category_id }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProducts({ limit: 50, latest: 1, category_id }));
  }, [category_id, dispatch]);

  const products = useSelector((state) => state.mainproduct.newProducts);
  const isLoading = useSelector((state) => state.mainproduct.isLoading);

  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 630,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const goToSlide = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  return (
    <div className="container">
      <Heading title={"New Arrivals"} />
      {isLoading ? (
        <div className="flex justify-center h-[calc(100vh-4rem)] items-center">
          <Loading />
        </div>
      ) : (
        <>
          <div className="hidden sm:block">
            {products?.length ? (
              <>
                {products?.length > 4 ? (
                  <>
                    <Slider ref={sliderRef} {...settings}>
                      {products?.map((product, index) => (
                        <SingleProduct product={product} key={index} />
                      ))}
                    </Slider>
                    <CustomScrollbar
                      totalSlides={products?.length}
                      currentSlide={currentSlide}
                      goToSlide={goToSlide}
                    />
                  </>
                ) : (
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-5 gap-y-7 lg:grid-cols-4 xs:grid-cols-2">
                    {products?.map((product, index) => (
                      <SingleProduct key={index} product={product} />
                    ))}
                  </div>
                )}
              </>
            ) : (
              <NoData />
            )}
          </div>

          <div className="sm:hidden">
            {products?.length ? (
              <div className="grid grid-cols-2 md:grid-cols-3 gap-2 gap-y-5 lg:grid-cols-4 xs:grid-cols-2">
                {products?.map((product, index) => (
                  <SingleProduct key={index} product={product} />
                ))}
              </div>
            ) : (
              <NoData />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default NewArrivals;
