import React, { forwardRef } from "react";
import { IoHelpCircle } from "react-icons/io5";
import { RiCustomerService2Line } from "react-icons/ri";
import { Link } from "react-router-dom";

const Header = forwardRef((props, ref) => {
  const gotoBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div ref={ref} className=" bg-black text-white h-[2rem] flex items-center">
      <div className="container flex justify-between">
        <a
          href="https://wa.me/9803411049"
          target="_blank"
          rel="noreferrer"
          className="flex items-center gap-2"
        >
          <RiCustomerService2Line />
          Customer Care
        </a>
        <Link to={"/register"} className="hidden items-center gap-2 md:flex">
          Become a member today and get exciting offers
        </Link>
        <button onClick={gotoBottom}>
          <button className="flex items-center gap-2">
            <IoHelpCircle /> Help
          </button>
        </button>
      </div>
    </div>
  );
});

export default Header;
