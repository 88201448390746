import React from "react";
import { Link } from "react-router-dom";
import Subcategory from "./SubCategory";

const DropdownCategories = ({
  hoverLeaveMouseEffectFunc,
  subCategoryData,
  hoverEffectFunc,
  closeMenu,
  filterSubCategory,
  visibleHeight,
}) => {
  return (
    <div
      style={{
        top: `${4 + visibleHeight / 16}rem`,
      }}
      onMouseLeave={hoverLeaveMouseEffectFunc}
      className={`dropdown-content border-t-[1px] fixed right-0 left-0 font-semibold z-[777] bg-white`}
    >
      <div className="container py-6">
        <ul className="flex justify-between">
          {subCategoryData?.map((category, index) => (
            <li key={index}>
              <div
                className="flex flex-col items-start"
                onMouseEnter={() =>
                  hoverEffectFunc(
                    category?.parent_id,
                    `/${category?.parent?.slug}/${category?.slug}`
                  )
                }
              >
                <Link
                  className="hover:underline w-full text-start"
                  to={`/${category?.parent?.slug}/${category?.slug}`}
                  onClick={closeMenu}
                >
                  <span className="text-sm tracking-wider">
                    {category.name}
                  </span>
                </Link>
                <ul className="mt-2">
                  <Subcategory
                    filterSubCategory={filterSubCategory}
                    category={category}
                    closeMenu={closeMenu}
                  />
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DropdownCategories;
