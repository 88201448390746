import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../apiConfig";

const initialState = {
  isLoading: false,
  wishlist: [],
  error: null,
  toastInfo: null,
};

export const getWishlist = createAsyncThunk(
  "wishlist/getWishlist",
  async ({ user_id }, thunkApi) => {
    try {
      const res = await api.get("wish-lists", {
        params: {
          user_id,
        },
      });
      return res.data.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const postWishlist = createAsyncThunk(
  "wishlist/postWishlist",
  async ({ user_id, product_id }, thunkApi) => {
    try {
      const res = await api.post("wish-lists", { user_id, product_id });
      return res.data.data;
    } catch (error) {
      if (error.response.status === 401) {
        return thunkApi.rejectWithValue("Please login for this action");
      } else {
        return thunkApi.rejectWithValue(error.message);
      }
    }
  }
);

export const deleteWishlist = createAsyncThunk(
  "wishlist/deleteWishlist",
  async ({ id }, thunkApi) => {
    try {
      await api.delete(`wish-lists/${id}`);
      return id; // Return the id of the deleted item
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    removeFromWishlist: (state, action) => {
      state.wishlist = state.wishlist.filter(
        (item) => item.id !== action.payload
      );
    },
    clearToast: (state) => {
      state.toastInfo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWishlist.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getWishlist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.wishlist = action.payload;
        state.error = null;
      })
      .addCase(getWishlist.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteWishlist.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteWishlist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.wishlist = state.wishlist.filter(
          (item) => item.id !== action.payload
        );
        state.error = null;
      })
      .addCase(deleteWishlist.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postWishlist.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(postWishlist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.wishlist = [action.payload, ...state.wishlist];
        state.error = null;
      })
      .addCase(postWishlist.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.payload);
        state.error = action.payload;
      });
  },
});

export const { removeFromWishlist, clearToast } = wishlistSlice.actions;
export default wishlistSlice.reducer;
