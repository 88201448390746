import React from "react";

const Heading = ({ title }) => {
  return (
    <div className="relative w-fit mb-5">
      <h2 className="text-2xl font-semibold uppercase tracking-wider">
        {title}
      </h2>
      {/* <span className="absolute w-[50%] h-[4px] bg-black -bottom-2" /> */}
    </div>
  );
};

export default Heading;
