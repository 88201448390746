import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMe } from "../../redux/features/LoginSlice";

export const MyAddresses = () => {
  const dispatch = useDispatch();

  const me = useSelector((state) => state.login.me);

  useEffect(() => {
    dispatch(fetchMe());
  }, [dispatch]);

  return (
    <Paper className="p-4">
      <Typography variant="h6" className="mb-4">
        Saved Addresses
      </Typography>
      <Divider sx={{ my: 1 }} />

      <Box className="mt-4">
        {me?.addresses?.length ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>District</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>Street Address</TableCell>
                  <TableCell>Landmark</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {me?.addresses?.map((address) => (
                  <>
                    <TableRow>
                      <TableCell>{address?.district}</TableCell>
                      <TableCell>{address?.city}</TableCell>
                      <TableCell>{address?.street_address}</TableCell>
                      <TableCell>{address?.landmark}</TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="w-full flex justify-center">No Saved Addresses</div>
        )}
      </Box>
    </Paper>
  );
};
