import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { postOTP, resendOTP } from "../redux/features/otpSlice";

const OTPpage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const isLoading = useSelector((state) => state.otp.isLoading);
  const me = useSelector((state) => state.login.me);

  useEffect(() => {
    if (me?.phone_verified_at) {
      navigate("/");
    }
  }, [me, navigate]);

  const handleChange = (otp) => {
    setOtp(otp);
  };

  const handleResendOTP = () => {
    dispatch(
      resendOTP({
        phone: localStorage.getItem("phone"),
        user_id: localStorage.getItem("id"),
      })
    );
  };

  const handleOTP = () => {
    dispatch(postOTP({ otp, user_id: localStorage.getItem("id"), navigate }));
  };

  return (
    <div className="flex flex-col items-center justify-center py-32">
      <h2 className="text-3xl font-semibold mb-3">
        An OTP has been sent to your phone
      </h2>
      <p className="text-md font-light mb-11">Enter your OTP code here</p>
      <OtpInput
        value={otp}
        onChange={handleChange}
        numInputs={4}
        renderSeparator={<span className="mx-2">-</span>}
        renderInput={(props) => (
          <input
            {...props}
            className="border rounded-lg border-gray-300 shadow-md text-5xl focus:outline-none "
          />
        )}
        containerStyle="flex justify-center mb-4"
        inputStyle=""
      />
      <div className="flex items-center gap-20 mt-10">
        <div className="text-center">
          <p>Didnt recive any code?</p>
          <button
            disabled={isLoading}
            onClick={handleResendOTP}
            className="rounded-md underline uppercase font-medium"
          >
            {isLoading ? "Sending..." : "Resend new code"}
          </button>
        </div>
        <button
          disabled={isLoading}
          onClick={handleOTP}
          className="p-2 px-7 rounded-md bg-black text-white"
        >
          {isLoading ? "Veryfing..." : "Verify"}
        </button>
      </div>
    </div>
  );
};

export default OTPpage;
