/* eslint-disable react-hooks/exhaustive-deps */
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { IoArrowBackOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import CartContext from "../context/CartContext";
import { fetchMe } from "../redux/features/LoginSlice";
import { postOrder } from "../redux/features/orderSlice";

const Checkout = () => {
  const { cartSummary, setCartSummary } = useContext(CartContext);

  const total = cartSummary.grandTotal;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.login.me);
  const cartData = useSelector((state) => state.cart.carts);
  const isLoading = useSelector((state) => state.order.isLoading);
  const setting = useSelector((state) => state.setting.settings);

  const [isSavedAddress, setIsSavedAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const totalQuantity = cartData?.reduce(
    (total, cart) => total + (cart.qty || 0),
    0
  );

  const totalPrice = cartData?.reduce((total, item) => {
    const price = item.price ? Number(item.price) : 0;
    const quantity = item.quantity ? Number(item.quantity) : 1;
    return total + price * quantity;
  }, 0);

  const deliveryCharge = setting.delivery_charges || 0;
  const grandTotal = totalPrice + deliveryCharge;

  useEffect(() => {
    setCartSummary({ totalPrice, deliveryCharge, grandTotal });
  }, [totalPrice, deliveryCharge, grandTotal, setCartSummary]);

  const products = cartData?.map((item) => ({
    product_id: item.product_id || null,
    variation_id: item.variation_id || null,
    qty: item.qty,
    price: item.price,
  }));

  const validationSchema = Yup.object({
    phone: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    district: Yup.string().required("Required"),
    street_address: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      city: "",
      district: "",
      landmark: "",
      street_address: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (selectedAddress) {
        delete values.city;
        delete values.district;
        delete values.landmark;
        delete values.street_address;
      }
      const orderData = {
        ...values,
        user_id: me?.id,
        products,
        total,
        ...(selectedAddress ? { address_id: selectedAddress } : {}),
      };

      dispatch(postOrder(orderData)).then(() => {
        navigate("/confirm-order");
      });
    },
  });

  useEffect(() => {
    if (me) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        phone: me.phone,
        name: me?.name,
      }));
    }
  }, [me]);

  useEffect(() => {
    dispatch(fetchMe());
  }, []);

  useEffect(() => {
    if (selectedAddress) {
      const addr = me?.addresses?.find((addr) => addr?.id === selectedAddress);
      formik.setValues((prevValues) => ({
        ...prevValues,
        city: addr?.city,
        district: addr?.district,
        landmark: addr?.landmark,
        street_address: addr?.street_address,
      }));
    } else {
      formik.setValues((prevValues) => ({
        ...prevValues,
        city: "",
        district: "",
        landmark: "",
        street_address: "",
      }));
    }
  }, [selectedAddress]);

  return (
    <div className="container py-14 px-4 lg:px-0">
      {cartData?.length === 0 ? (
        <div className="">
          <p className="text-2xl mb-5 font-medium">Your cart is empty.</p>
          <Link to="/">
            <button className="flex items-center gap-1 hover:bg-white hover:text-black bg-black text-white duration-300 border border-black p-3 px-5 uppercase">
              <IoArrowBackOutline /> Go back to shopping
            </button>
          </Link>
        </div>
      ) : (
        <>
          <h2 className="uppercase text-3xl text-center font-bold pb-20">
            Checkout
          </h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="flex md:flex-row gap-24 flex-col">
              <div className="md:w-[60%]">
                <h2 className="uppercase text-lg font-bold pb-5">Contact</h2>
                <div className="flex gap-5 items-end">
                  <div>
                    <TextField
                      type="text"
                      label="Enter Phone"
                      className="p-2 border border-gray-400 w-72"
                      name="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                  </div>
                </div>
                <hr className="my-5 mt-7 border border-gray-300 w-full" />
                <div className="flex flex-col gap-4">
                  <h2 className="uppercase text-lg font-bold">Address</h2>
                  {/* TODO: saved addresses */}
                  <div
                    onClick={() =>
                      setIsSavedAddress((prev) => {
                        if (prev) {
                          setSelectedAddress(null);
                          return false;
                        } else {
                          return true;
                        }
                      })
                    }
                    className={`${
                      isSavedAddress ? "bg-black text-white" : "bg-white"
                    } border rounded-lg px-4 py-1 w-fit cursor-pointer`}
                  >
                    Saved Addresses
                  </div>
                  {isSavedAddress ? (
                    <div className="flex gap-5 rounded-lg flex-wrap">
                      {me?.addresses?.map((addr, index) => (
                        <div
                          className={`${
                            selectedAddress === addr?.id
                              ? "bg-black text-white"
                              : "bg-gray-100"
                          } rounded-lg p-4 cursor-pointer`}
                          key={index}
                          onClick={() => setSelectedAddress(addr?.id)}
                        >
                          <div className="flex gap-2 items-center">
                            <label>District: </label>
                            <span>{addr?.district} </span>
                          </div>

                          <div className="flex gap-2 items-center">
                            <label>City: </label>
                            <span>{addr?.city} </span>
                          </div>

                          <div className="flex gap-2 items-center">
                            <label>Street Address: </label>
                            <span>{addr?.street_address} </span>
                          </div>

                          <div className="flex gap-2 items-center">
                            <label>Landmark: </label>
                            <span>{addr?.landmark} </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}

                  <p className="pb-4 font-semibold">Delivery address</p>
                  <div className="grid grid-cols-2 gap-5">
                    <div className="col-span-2">
                      <TextField
                        type="text"
                        label="Enter your full name"
                        className="p-2 border border-gray-400 w-full"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </div>
                    <div>
                      <TextField
                        disabled={selectedAddress}
                        type="text"
                        label="City"
                        className="p-2 border border-gray-400 w-full"
                        name="city"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.city && Boolean(formik.errors.city)
                        }
                        helperText={formik.touched.city && formik.errors.city}
                      />
                    </div>
                    <div>
                      <TextField
                        disabled={selectedAddress}
                        type="text"
                        label="District"
                        className="p-2 border border-gray-400 w-full"
                        name="district"
                        value={formik.values.district}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.district &&
                          Boolean(formik.errors.district)
                        }
                        helperText={
                          formik.touched.district && formik.errors.district
                        }
                      />
                    </div>
                    <div>
                      <TextField
                        disabled={selectedAddress}
                        type="text"
                        label="Landmark"
                        className="p-2 border border-gray-400 w-full"
                        name="landmark"
                        value={formik.values.landmark}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.landmark &&
                          Boolean(formik.errors.landmark)
                        }
                        helperText={
                          formik.touched.landmark && formik.errors.landmark
                        }
                      />
                    </div>

                    <div>
                      <TextField
                        disabled={selectedAddress}
                        type="text"
                        label="Street Address"
                        className="p-2 border border-gray-400 w-full"
                        name="street_address"
                        value={formik.values.street_address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.street_address &&
                          Boolean(formik.errors.street_address)
                        }
                        helperText={
                          formik.touched.street_address &&
                          formik.errors.street_address
                        }
                      />
                    </div>

                    <div className="flex gap-5 items-center">
                      <h2>Payment method:</h2>
                      <button className="bg-black text-white px-4 py-1 font-bold rounded">
                        COD
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:w-[40%]">
                <div className="bg-[#F5F5F5] rounded-md sticky top-36 px-7 py-8">
                  <h2 className="uppercase text-lg font-bold pb-3">
                    Order Summary
                  </h2>
                  <div className="flex justify-between pb-3">
                    <h2>Total Items:</h2>
                    <h2>{totalQuantity}</h2>
                  </div>
                  <div className="flex justify-between pb-3">
                    <h2>Total Price: </h2>
                    <h2>Rs. {cartSummary.totalPrice}</h2>
                  </div>
                  <div className="flex justify-between pb-3">
                    <h2>Delivery Charge: </h2>
                    <h2>Rs. {cartSummary.deliveryCharge}</h2>
                  </div>
                  <hr className="border-gray-400" />
                  <div className="flex justify-between pt-3">
                    <h2>Grand Total:</h2>
                    <h2>Rs. {cartSummary.grandTotal}</h2>
                  </div>
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="bg-black text-white w-full p-4 mt-7 uppercase text-[15px] font-medium flex items-center justify-center gap-2 rounded-md hover:bg-[#242424] duration-300"
                  >
                    {isLoading ? "Loading ..." : "order"}
                    <span>
                      <BsArrowRight className="text-lg" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default Checkout;
