import React from "react";
import { Link } from "react-router-dom";

const Subcategory = ({ filterSubCategory, category, closeMenu }) => {
  const fitleredItems = filterSubCategory?.filter(
    (cat) => cat?.parent?.id === category?.id
  );

  return (
    <div className="flex flex-col items-start">
      {fitleredItems?.map((subCategory, subIndex) => (
        <li key={subIndex}>
          <Link
            to={`/${category?.parent?.slug}/${subCategory?.slug}`}
            onClick={closeMenu}
            className="hover:underline font-normal text-start"
          >
            <span className="text-sm mt-1 tracking-wider">
              {subCategory?.name}
            </span>
          </Link>
        </li>
      ))}
    </div>
  );
};

export default Subcategory;
