import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrder } from "../../redux/features/orderSlice";

const tabData = [
  { label: "Processing", value: "Processing" },
  { label: "Ready", value: "Ready" },
  { label: "Completed", value: "Completed" },
  { label: "Returned", value: "Returned" },
  { label: "Refunded", value: "Refunded" },
];

export const MyOrders = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("Processing");
  const [activeRow, setActiveRow] = useState("");
  const [open, setOpen] = useState(false);

  const me = useSelector((state) => state.login.me);
  const orders = useSelector((state) => state.order.order);

  useEffect(() => {
    if (me?.id) {
      dispatch(getOrder({ user_id: me?.id, status: activeTab }));
    }
  }, [activeTab, dispatch, me?.id]);

  const handleOpenProducts = (id) => {
    setOpen(() => (id === activeRow ? !open : true));
    setActiveRow(id);
  };

  return (
    <Paper className="p-4">
      <Typography variant="h6" className="mb-4">
        Order History
      </Typography>
      <Divider sx={{ my: 1 }} />
      {tabData.map((tab) => (
        <button
          key={tab.value}
          className={`px-4 py-2 -mb-px text-sm font-medium border-b-4 w-fit ${
            activeTab === tab.value
              ? "border-black text-black"
              : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
          }`}
          onClick={() => setActiveTab(tab.value)}
        >
          {tab.label}
        </button>
      ))}
      <Box className="mt-4">
        {orders?.length ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {orders?.map((order) => (
                  <>
                    <TableRow>
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => handleOpenProducts(order?.id)}
                        >
                          {open && activeRow === order?.id ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>{order?.code}</TableCell>
                      <TableCell>
                        {moment(order?.created_at).format("DD MMM, YYYY")}
                      </TableCell>
                      <TableCell>Rs. {order?.total}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={open && activeRow === order?.id}
                          unmountOnExit
                        >
                          <Box margin={1}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              Order Products
                            </Typography>
                            <Table size="small" aria-label="order-items">
                              <TableHead>
                                <TableRow>
                                  <TableCell></TableCell>
                                  <TableCell>Product Name</TableCell>
                                  <TableCell>Quantity</TableCell>
                                  <TableCell>Price</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {order?.items?.map((item) => (
                                  <TableRow key={item?.id}>
                                    <TableCell>
                                      <img
                                        className="w-20 h-20"
                                        src={item?.product?.gallery?.[0]}
                                        alt=""
                                      />
                                    </TableCell>
                                    <TableCell>{item?.product?.name}</TableCell>
                                    <TableCell>{item?.qty}</TableCell>
                                    <TableCell>Rs. {item?.price}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="flex w-full justify-center">No Orders</div>
        )}
      </Box>
    </Paper>
  );
};
