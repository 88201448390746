import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../apiConfig';

const initialState = {
  isLoading: false,
  categories: [],
  error: null,
};

export const getCategory = createAsyncThunk('category/getCategory', async (_, thunkApi) => {
  try {
    const res = await api.get('categories');
    return res.data.data; // Adjust based on actual response structure
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});


const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategory.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categories = Array.isArray(action.payload) ? action.payload : [];
        state.error = null;
      })
      .addCase(getCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

// export const selectMenCategory = (state) => state.maincategory.categories.filter(category => category?.name === 'Mens');
// export const selectWomenCategory = (state) => state.maincategory.categories.filter(category => category.parent?.name === 'Womens');
// export const selectKidsCategory = (state) => state.maincategory.categories.filter(category => category.parent?.name === 'Kids');
// export const selectHomeCategory = (state) => state.maincategory.categories.filter(category => category?.name === 'Home');

export default categorySlice.reducer;