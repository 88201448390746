import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../apiConfig";
import { fetchCartItems } from "./cartSlice";

const initialState = {
  isLoading: false,
  order: [],
  error: null,
  orderStatus: null,
};

export const postOrder = createAsyncThunk(
  "postOrder/order",
  async (OrderData, thunkApi) => {
    try {
      const res = await api.post("orders", OrderData);
      thunkApi.dispatch(fetchCartItems({ user_id: OrderData?.user_id }));
      return res.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getOrderCode = createAsyncThunk(
  "getOrderCode/order",
  async ({ code }, thunkApi) => {
    try {
      const res = await api.get(`orders/${code}`, {
        params: {
          code,
        },
      });
      return res.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getOrder = createAsyncThunk(
  "getOrder/order",
  async ({ user_id, status }, thunkApi) => {
    try {
      const res = await api.get("orders", {
        params: {
          user_id,
          status,
        },
      });
      return res.data.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("order successfully!");
      })
      .addCase(postOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.isLoggedIn = false;
        toast.error(JSON.stringify(action.payload.message || action.payload));
      });

    builder
      .addCase(getOrderCode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrderCode.fulfilled, (state, action) => {
        state.isLoading = false;
        state.orderStatus = action.payload;
      })
      .addCase(getOrderCode.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        toast.error(action.payload.response.data.message);
      });

    builder
      .addCase(getOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.order = action.payload;
      })
      .addCase(getOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.isLoggedIn = false;
      });
  },
});

export default orderSlice.reducer;
