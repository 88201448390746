/* eslint-disable react/prop-types */
import { createContext, useState } from "react";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartSummary, setCartSummary] = useState({
    totalPrice: 0,
    deliveryCharge: 0,
    grandTotal: 0,
  });

  return (
    <CartContext.Provider value={{ cartSummary, setCartSummary }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
