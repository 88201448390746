// import React from 'react'

import { Link } from "react-router-dom";

const OrderPlaced = () => {
  return (
    <>
      <div className="container py-16 text-center">
        <img src="/assets/confirm-order.jpg" className="w-80 mx-auto" alt="" />
        <h2 className="text-2xl font-medium">Order Confirmed</h2>
        <Link to="/">
          <button className="p-3 px-7 bg-black text-white w-[20%] mx-auto mt-6">
            Back to Home
          </button>
        </Link>
      </div>
    </>
  );
};

export default OrderPlaced;
