import React from "react";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";

const BreadCrumb = ({ product }) => {
  const navigate = useNavigate();
  // TODO: get the Category, sub-category and sub-sub-categories
  return (
    <div className="mb-gap flex gap-2 items-center flex-wrap">
      <IoReturnUpBackOutline
        className="font-bold cursor-pointer"
        size={25}
        onClick={() => navigate(-1)}
      />
      <Link
        to={"/"}
        className="underline hover:bg-black hover:text-white tracking-wider"
      >
        Home
      </Link>
      /
      <Link
        to={`/${product?.category?.slug}`}
        className="capitalize underline hover:bg-black hover:text-white tracking-wider"
      >
        {product?.category?.name?.toLowerCase()}
      </Link>
      /
      <Link
        to={`/${product?.category?.slug}/${product?.sub_category?.slug}`}
        className="capitalize underline hover:bg-black hover:text-white tracking-wider"
      >
        {product?.sub_category?.name?.toLowerCase()}
      </Link>
      /
      <Link
        to={`/${product?.category?.slug}/${product?.sub_sub_category?.slug}`}
        className="capitalize underline hover:bg-black hover:text-white tracking-wider"
      >
        {product?.sub_sub_category?.name?.toLowerCase()}
      </Link>
    </div>
  );
};

export default BreadCrumb;
