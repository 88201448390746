import React from "react";
import Banner from "../sections/landing/Banner";
import Featured from "../sections/landing/Featured";
import MainCategory from "../sections/landing/MainCategories";
import NewArrivals from "../sections/landing/NewArrivals";
import Offers from "../sections/landing/Offers";
import Trending from "../sections/landing/Trending";

const Landing = () => {
  return (
    <div>
      <Banner />
      <div className="flex flex-col gap-gap">
        <NewArrivals />
        <Offers />
        <Featured />
        <MainCategory />
        <Trending />
      </div>
    </div>
  );
};

export default Landing;
