// UserDashboard.js
import { ChangePassword } from "../sections/userDashboard/ChangePassword";
import { MyAddresses } from "../sections/userDashboard/MyAddresses";
import { MyOrders } from "../sections/userDashboard/MyOrders";
import { MyProfile } from "../sections/userDashboard/MyProfile";

const UserDashboard = () => {
  return (
    <div className="container flex flex-col md:flex-row min-h-screen w-full my-gap pt-gap">
      <div className="gap-5 w-full flex xl:flex-row flex-col">
        <div className="flex-1 flex flex-col gap-4">
          <MyProfile />
          <ChangePassword />
        </div>
        <div className="flex-[2] flex flex-col gap-4">
          <MyAddresses />
          <MyOrders />
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
